import { Card, Container, Row, Col, Accordion } from 'react-bootstrap';
import './../../App.css';
export function FAQ() {
  return (
    <Container className='d-flex justify-content-center flex-column mt-custom'>
      <h1 className='mt-5'>Frequently Asked Questions</h1>
      <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>What is TrimyLink?</Accordion.Header>
          <Accordion.Body>
            TrimyLink is an online platform that offers a suite of tools to
            simplify and optimize your online presence. Our services include URL
            shortening, QR code generation, and Link In Bio management.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>How does URL shortening work?</Accordion.Header>
          <Accordion.Body>
            URL shortening is the process of converting a long URL into a
            shorter, more manageable link. TrimyLink's URL shortener tool allows
            you to shorten links with just a click, making them easier to share
            and track.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>Why should I use QR codes?</Accordion.Header>
          <Accordion.Body>
            QR codes are versatile tools that can be used to drive traffic,
            enhance engagement, and provide additional information to users.
            With TrimyLink's QR code generator, you can create dynamic QR codes
            for websites, contact information, social media profiles, and more.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='3'>
          <Accordion.Header>
            {' '}
            What is Link In Bio, and how does it benefit me?
          </Accordion.Header>
          <Accordion.Body>
            Link In Bio is a feature that allows you to create a centralized
            landing page for all your important links. Instead of being limited
            to a single link on your social media profiles, you can showcase
            multiple links in one convenient location, making it easier for your
            audience to discover and engage with your content.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='4'>
          <Accordion.Header>Is TrimyLink free to use?</Accordion.Header>
          <Accordion.Body>
            Yes, TrimyLink offers a free plan with basic features for all users.
            Additionally, we offer premium plans with advanced functionalities
            for those who require more robust tools for their online marketing
            efforts.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='5'>
          <Accordion.Header>
            Can I track the performance of my shortened URLs and QR codes?
          </Accordion.Header>
          <Accordion.Body>
            Absolutely! TrimyLink provides comprehensive analytics tracking for
            both shortened URLs and generated QR codes. You can monitor metrics
            such as click-through rates, geographic location of clicks, and
            device types to gain valuable insights into the effectiveness of
            your campaigns.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='6'>
          <Accordion.Header>Is my data secure with TrimyLink?</Accordion.Header>
          <Accordion.Body>
            We take data security and privacy seriously at TrimyLink. We utilize
            industry-standard encryption protocols to ensure that your data
            remains secure at all times. Additionally, we do not share your data
            with third parties without your consent.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='7'>
          <Accordion.Header>
            How can I get started with TrimyLink?
          </Accordion.Header>
          <Accordion.Body>
            Getting started with TrimyLink is quick and easy! Simply sign up for
            an account on our website, and you'll have access to all our tools
            and features. Whether you're a marketer, business owner, or social
            media influencer, TrimyLink has everything you need to optimize your
            online presence.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='8'>
          <Accordion.Header>
            I'm facing issue while using the service and it shows me reference
            number, what to do ?
          </Accordion.Header>
          <Accordion.Body>
            Please submit a ticket using the contact-us section or by sending
            email with the reference number and our team will get back to you as
            soon as possible.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='9'>
          <Accordion.Header>
            What is API key integration, and how does it benefit me?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              API key integration allows developers to seamlessly integrate
              TrimyLink's link shortening, QR code generation, and analytics
              features directly into their own applications, tools, or
              workflows. This opens up a world of possibilities, such as:
            </p>
            <ul>
              <li>
                <b>Automating Link Creation:</b> Create short links or QR codes
                for your products or content automatically.
              </li>
              <li>
                <b>Custom Workflows:</b> Integrate TrimyLink into your existing
                systems and processes.
              </li>
              <li>
                <b>Building Custom Solutions:</b> Develop unique applications on
                top of TrimyLink's robust platform.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='10'>
          <Accordion.Header>
            How do I get started with the TrimyLink API?
          </Accordion.Header>
          <Accordion.Body>
            <p>To get started, you'll need to:</p>
            <ol>
              <li>Sign up for a TrimyLink account (if you haven't already).</li>
              <li>Upgrade to a paid plan to access the API.</li>
              <li>
                Visit our API documentation to learn about authentication,
                endpoints, and available features.
              </li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            Q: What happens if I don't upgrade to a paid plan?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              You can still use TrimyLink's free plan, which allows you to
              create and share links and QR codes without the advanced features
              of the API.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
