import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Card,
  Button,
  Pagination,
  Dropdown,
  Col,
} from 'react-bootstrap';
import { MdModeEditOutline } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { BsQrCode } from 'react-icons/bs';
import { IoMdDownload } from 'react-icons/io';
import { ProfileLinkEditModal } from './ProfileLinkEditModal';
import { ProfileLinkDeleteModal } from './ProfileLinkDeleteModal';
import { useNavigate } from 'react-router';
import { Loading } from '../Loading';
import { ErrorTextModal } from '../popup/ErrorTextModal';

export function ProfileQR() {
  const [projectDeleted, setProjectDeleted] = useState(false);
  const [project, setProject] = useState();
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [show, setShow] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [state, setState] = useState({
    data: [],
    limit: 10,
    offset: 0,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchProjects = async (limit: number, offset: number) => {
    setLoadingVisible(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/qr/qrs?limit=${limit}&offset=${offset}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setLoadingVisible(false);
      if (response.ok) {
        const result = await response.json();
        console.log('result', result);
        setTotalCount(result.totalCount);
        setState((prev) => ({
          offset: prev.offset + result.data.length,
          limit: prev.limit,
          data: result.data,
        }));
        console.log(state);
      } else {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  };

  useEffect(() => {
    fetchProjects(state.limit, state.offset);
  }, [projectDeleted]);
  const handlePageChange = (pageNumber: number) => {
    setState((prev) => ({ ...prev, activePage: pageNumber }));
    if (pageNumber === 1) {
      fetchProjects(state.limit, 0);
    } else {
      fetchProjects(state.limit, pageNumber * 10 - 10);
    }
  };

  function handleDeleteProject(shortId: string) {
    state.data = state.data.filter(
      (project: any) => project.shortid !== shortId
    );
    setProjectDeleted(!projectDeleted);
    state.limit = 10;
    state.offset = 0;
  }

  async function downloadQR(project: any, format: string) {
    setLoadingVisible(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/qr/download/${project.shortid}?format=${format}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setLoadingVisible(false);
      if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      } else {
        const result = await response.blob();
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${project.name}.${format}`); // or any other image format or file name
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  }

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Container
          id='theta-projects'
          fluid
          className='our-projects mt-5 y-flex justify-content-center align-content-center center'
          style={{ backgroundColor: 'transparent' }}
        >
          <Row className='d-flex justify-content-center align-content-center center'>
            {state && state.data && state.data.length > 0 ? (
              state.data.map((project: any, index) => (
                <Card
                  key={index}
                  style={{ width: '18rem' }}
                  className='mx-3 my-3 project-card'
                >
                  <Card.Header
                    style={{ backgroundColor: 'transparent' }}
                    className='d-flex align-items-center center justify-content-center'
                  >
                    <BsQrCode
                      size={30}
                      style={{ color: '#135D66' }}
                      className='mx-3'
                    />{' '}
                    {project.name}
                  </Card.Header>
                  <Card.Img variant='top' src={project.qrImage} />
                  <Card.Body>
                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>Orginal Url: </p>
                      {project.orginalUrl}
                    </Card.Text>
                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>active: </p>
                      {project.active ? 'Yes' : 'No'}
                    </Card.Text>

                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>Created at: </p>
                      {new Date(project.createdAt).toISOString().split('T')[0]}
                    </Card.Text>

                    <Card.Text>
                      <p style={{ fontWeight: 'bold' }}>Shorten Url:</p>
                      <a
                        target='_blank'
                        href={project.shortenedUrl}
                        rel='noreferrer'
                      >
                        {project.shortenedUrl}{' '}
                      </a>
                    </Card.Text>
                    <Button
                      variant='success'
                      onClick={() => {
                        handleShow();
                        setProject(project);
                        setSelectedStatus(
                          project.active ? 'Active' : 'Inactive'
                        );
                      }}
                    >
                      <MdModeEditOutline size={30} className='mx-1' />
                      Edit
                    </Button>
                    <Button
                      variant='danger'
                      className='mx-md-2 my-2 mx-sm-2'
                      onClick={() => {
                        handleShowDeleteModal();
                        setProject(project);
                      }}
                    >
                      <MdDelete size={30} className='mx-1' />
                      Delete
                    </Button>
                    {/* <Button variant='success' className='mx-2 my-2'> */}
                    <Dropdown className='mb-3 mt-3 sm-w-50 xs-w-25 md-w-25'>
                      <Dropdown.Toggle
                        variant='success'
                        id='dropdown-basic'
                        className='w-100'
                      >
                        <IoMdDownload size={30} className='mx-1' />
                        Download
                      </Dropdown.Toggle>
                      <Row className='justify-content-center'></Row>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => downloadQR(project, 'PNG')}
                        >
                          PNG
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => downloadQR(project, 'JPEG')}
                        >
                          JPEG
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => downloadQR(project, 'SVG')}
                        >
                          SVG (Black & White)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <Row className='d-flex align-items-center'>
                <Col className='d-flex justify-content-end'>
                  <p style={{ color: '#2C4E80' }}>
                    You have not generated any QR yet ,{' '}
                  </p>
                </Col>
                <Col className='d-flex justify-content-start align-items-center center'>
                  <Button
                    variant='success'
                    onClick={() => navigate('/QRShortenPage')}
                  >
                    Generate QR
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
          <ProfileLinkEditModal
            handleShow={handleShow}
            handleClose={handleClose}
            show={show}
            project={project}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            setSuccessText={setSuccessText}
            setErrorText={setErrorText}
          />
          <ProfileLinkDeleteModal
            handleShowDeleteModal={handleShowDeleteModal}
            handleCloseDeleteModal={handleCloseDeleteModal}
            showDeleteModal={showDeleteModal}
            project={project}
            setSuccessText={setSuccessText}
            setErrorText={setErrorText}
            handleDeleteProject={handleDeleteProject}
          />
          <Pagination className='px-4'>
            {Array.from({ length: Math.ceil(totalCount / 10) }, (_, index) => (
              <Pagination.Item
                onClick={() => handlePageChange(index + 1)}
                key={index + 1}
                active={index + 1 === state.offset}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
          <ErrorTextModal
            errorText={errorText}
            show={errorText.length > 0}
            onHide={() => setErrorText('')}
          />
        </Container>
      )}
    </>
  );
}
