import { Button, Col, Container, Row } from 'react-bootstrap';
import './../../../LinkInBioIntro.css';
import Header from '../../home-page/Header';
import { Footer } from '../../home-page/Footer';
import { useContext, useEffect, useState } from 'react';
import { TrimyLinkContext } from '../../../store/TrimyLinkContext';
import { useNavigate } from 'react-router';
export function LinkInBioIntroPage() {
  const trimyCtx = useContext(TrimyLinkContext);
  const [buttonText, setButtonText] = useState('Get Started For Free');
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      setButtonText('Create Your Link In Bio');
    } else {
      setButtonText('Get Started For Free');
    }
  }, [trimyCtx]);

  function createLinkInBio() {
    if (localStorage.getItem('jwtToken')) {
      navigate('/Profile', { state: { selectedTab: 'linkInBio' } });
    } else {
      navigate('/signup');
    }
  }
  return (
    <Container>
      <Header />
      <Row>
        <Row>
          <div className='link-in-bio-intro text-center'>
            <h2 className='mb-4'>Welcome to TrimyLink Link-In-Bio Service!</h2>
            <p className='lead'>
              Are you tired of limited space in your social media bios? Do you
              wish you could share more about yourself or your brand with your
              followers? Look no further! Our Link in Bio service is here to
              revolutionize the way you connect with your audience.
            </p>
            <Button
              variant='success'
              className='w-25'
              onClick={createLinkInBio}
            >
              {buttonText}
            </Button>
          </div>
        </Row>
      </Row>
      <Row>
        <Col sm={6} md={6}>
          <div>
            <Row>
              <div className='text-left'>
                <h3>What is Link in Bio?</h3>
                <p>
                  Link in Bio is a dynamic tool that allows you to create a
                  single, customizable landing page where you can showcase all
                  your important links, content, and promotions in one place.
                  Instead of being limited to a single link in your social media
                  bios, Link in Bio empowers you to share multiple links
                  effortlessly.
                </p>
              </div>
            </Row>
            <Row>
              <h3>How Does It Work?</h3>
              <p>
                Getting started with Link in Bio is quick and easy. Simply sign
                up for our service, create your personalized landing page, and
                start adding links to your favorite content. Whether you're
                promoting your latest blog post, sharing your online store, or
                directing followers to your social media profiles, Link in Bio
                provides a centralized platform to drive traffic and engagement.
              </p>
            </Row>
          </div>
        </Col>

        <Col
          sm={6}
          md={6}
          className='d-flex align-items-center center justify-content-center'
        >
          <img
            src={`${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/images/Hero1.svg`}
            alt='Link in Bio'
            className='fluid'
          />
        </Col>
      </Row>
      <Row>
        <h3>Key Features</h3>
        <ul>
          <li className='mt-3'>
            Effortless Sharing: Say goodbye to the hassle of constantly updating
            your social media bios. With our intuitive platform, you can easily
            add, remove, or rearrange links with just a few clicks.
          </li>
          <li className='mt-3'>
            Personalized Branding: Make a lasting impression with a beautifully
            designed landing page that reflects your unique style and brand
            identity. Customize colors, fonts, backgrounds, and more to create a
            seamless brand experience for your audience.
          </li>
          <li className='mt-3'>
            Trackable Analytics: Gain valuable insights into your audience's
            behavior and preferences with our built-in analytics dashboard.
            Track link clicks, page views, and other key metrics to optimize
            your content strategy and drive engagement.
          </li>
          <li className='mt-3'>
            Promotion and Monetization: Whether you're promoting products,
            services, or affiliate links, Link in Bio provides a centralized
            platform to showcase your offerings and drive conversions. Monetize
            your bio link by adding sponsored content or affiliate links and
            start earning revenue today.
          </li>
        </ul>
      </Row>

      <Footer />
    </Container>
  );
}
