import { Card, Button, Container, Row, Col } from 'react-bootstrap';

import './../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ErrorTextModal } from '../popup/ErrorTextModal';
export function Products() {
  const [serviceButtonText, setServiceButtonText] = useState('Get Started');
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    const requestId = Date.now().toString();
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/trimyLink/products`,
          {
            method: 'GET',

            headers: {
              'Content-Type': 'application/json',
              'request-id': requestId,
            },
            credentials: 'include',
          }
        );

        if (!response.ok) {
          if (response.status === 400) {
            const error = (await response.json()).message;
            setErrorText(error);
            setShowError(true);
          } else {
            setErrorText(
              `Ops something went wrong at our end -- Reference Number: ${requestId}`
            );
            setShowError(true);
          }
          return;
        }
        setErrorText('');
        setShowError(false);
        setProducts(await response.json());
      } catch (error) {
        setErrorText(
          `Ops something went wrong at our end -- Reference Number: ${requestId}`
        );
        setShowError(true);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  return (
    <Container className='mt-custom'>
      <Row className='align-items-center center justify-content-center'>
        <Col xs={6} md={3}>
          <p className='h1'>Our Products</p>
        </Col>
      </Row>

      <Row className='justify-content-center'>
        {products.map((product: any) => (
          <Col>
            <Card className='mb-4 h-100'>
              <Row className='ml-5 mt-5'>
                <Col
                  xs={12}
                  md={12}
                  className='d-flex align-items-center justify-content-center'
                >
                  <img
                    src={product.imageUrl}
                    alt='shorte url, qr codes and link in bio products'
                    width='40px'
                    height='40px'
                  />

                  <Card.Title className='mb-0'>{product.name}</Card.Title>
                </Col>
              </Row>
              <Card.Body>
                <Card.Text className='d-flex justify-content-center flex-row'>
                  <ul className='list-unstyled'>
                    {product.features.map((feature: string) => (
                      <p style={{ marginLeft: '0.5rem' }} className='mb-0'>
                        <li className='d-flex align-items-center mt-3'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='42'
                            height='42'
                            fill='currentColor'
                            className='bi bi-check-circle mr-2'
                          >
                            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
                            <path d='m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05' />
                          </svg>
                          <p style={{ marginLeft: '0.5rem' }} className='mb-0'>
                            {feature}
                          </p>
                        </li>
                      </p>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
              <Card.Footer className='bg-transparent'>
                <Button
                  variant='primary'
                  className='w-100'
                  hidden={localStorage.getItem('jwtToken') !== null}
                  onClick={
                    serviceButtonText === 'Get Started'
                      ? () => navigate('/signup')
                      : () => {}
                  }
                >
                  {serviceButtonText}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </Container>
  );
}
