import { Container, Row } from 'react-bootstrap';
import Header from './home-page/Header';
import { Footer } from './home-page/Footer';

export function CookiePolicy() {
  return (
    <Container className='mx-3 mt-3 px-3'>
      <Row>
        <Header />
      </Row>
      <Row>
        <h3>
          <b>COOKIE POLICY</b>
        </h3>
      </Row>
      <Row className='mt-3'>
        <h4>
          <b>Welcome to TrimyLink!</b>
        </h4>
      </Row>
      <Row>
        <p>
          This cookie policy explains how and why cookies and other similar
          technologies may be stored on and accessed from your device when you
          use or visit:
        </p>
        <ul>
          <li>
            <a
              href='https://trimylink.io'
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              https://trimylink.io
            </a>
            <p>(Hereinafter referred to as “TrimyLink”).</p>
            <p>
              The information collected through cookies will be under
              responsibility and in charge of:
            </p>
            <ul>
              <li>TrimyLink.</li>
              <li>Email: support@trimylink.io</li>
            </ul>
            <p>
              This cookie policy should be read together with our privacy policy
              and our terms and conditions.
            </p>
            <p>
              By using the website, you accept the use of cookies by TrimyLink,
              in the terms contained in this policy.
            </p>
          </li>
        </ul>
      </Row>

      <Row>
        <ol>
          <li>
            {' '}
            <b>WHAT ARE COOKIES?</b>
            <p>
              Cookies are small text files that are stored on your computer or
              mobile device when you visit a website. They allow the website to
              recognize your device and remember if you have been to the website
              before. Cookies are a very common web technology; most websites
              use cookies and have done so for years. Cookies are widely used to
              make the website work more efficiently. Cookies are used to
              measure which parts of the website users visit and to personalize
              their experience. Cookies also provide information that helps us
              monitor and improve the performance of the website.
            </p>
          </li>
          <li>
            <b>CONSENT</b>
            <p>
              When you access our website, you will be presented with a notice
              about the use of cookies. By clicking 'Accept' on this notice, you
              provide your explicit consent to the use of cookies on your
              device. These cookies are used to enhance your browsing
              experience, personalize content, analyze our site traffic and
              provide specific functionality related to our online operations.{' '}
            </p>
            <p>
              Your consent allows us to provide you with a more personalized and
              efficient user experience. If you wish to withdraw your consent at
              any time, you may do so by changing the cookie settings in your
              browser. However, please note that disabling certain cookies may
              limit the functionality of the site and affect your user
              experience.
            </p>
          </li>
          <li>
            <b>LEGAL BASIS AND LEGITIMATE INTERESTS</b>
            <p>
              On our website, we use cookies based on our legitimate interests
              to ensure the best user experience and to continuously improve our
              website. These interests include optimizing our online offering,
              personalizing content and advertising, as well as ensuring the
              security and efficiency of our website.{' '}
            </p>
            <p>
              Cookies help us understand how users interact with our website,
              which allows us to adjust and improve our services and
              functionalities according to their needs and preferences.{' '}
            </p>
            <p>
              While we believe that these interests do not outweigh your
              fundamental rights and freedoms, you have the option to refuse or
              manage the use of cookies through your browser settings or the
              tools we provide on our website.
            </p>
          </li>

          <li>
            <b>WITHDRAWAL AND OBJECTION (OPT-OUT)</b>
            <p>
              By accessing our website, you agree to the use of cookies that
              enhance the browsing experience and allow the personalization of
              content and advertisements. However, we understand and respect
              your right to privacy. If you wish to opt-out or exercise your
              choice to not participate (Opt-Out), you can set your browser to
              refuse all cookies or to indicate when a cookie is being sent.
            </p>
            <p>
              Please note that some features of the site may not function
              properly without the use of cookies. In addition, we provide links
              or tools on our site that allow you to specifically decline the
              use of certain cookies.
            </p>
          </li>
          <li>
            <b>STORAGE DURATION OF COOKIES</b>
            <p>
              The cookies used on our website have different storage periods,
              depending on their purpose. Essential cookies, necessary for the
              basic operation of the website, are kept as long as necessary to
              provide the requested services.{' '}
            </p>
            <p>
              Functionality cookies, which remember your preferences and
              settings, are stored for a period that can vary from the duration
              of your visit (session cookies) to several years, to ensure a
              constant user experience. Analytics and advertising cookies have a
              maximum storage duration of 24 months, after which they will be
              automatically deleted.{' '}
            </p>
            <p>
              You can delete or manage these cookies at any time through your
              browser settings. Deleting or rejecting cookies may affect the
              functionality of the site and the quality of your experience on
              our website.
            </p>
          </li>
          <li>
            <b>COOKIE SETTINGS</b>
          </li>
          <p>
            If you do not want cookies to be dropped on your device, you can
            adjust the setting of your Internet browser to reject the setting of
            all or some cookies and to alert you when a cookie is placed on your
            device. For further information about how to do so, please refer to
            your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if
            you use your browser settings to block all cookies, including
            strictly necessary cookies, you may not be able to access or use all
            or parts of the functionalities of TrimyLink.
          </p>
          <p>
            If you want to remove previously-stored cookies, you can manually
            delete the cookies at any time. However, this will not prevent
            TrimyLink from placing further cookies on your device unless and
            until you adjust your Internet browser setting as described above.
          </p>
          <p>
            We provide the links for the management and blocking of cookies
            depending on the browser you use:
          </p>
          <ul>
            <li>
              <b>Microsoft Edge:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us'
              >
                https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us
              </a>
            </li>
            <li>
              <b>Firefox:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox'
              >
                https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
              </a>
            </li>
            <li>
              <b>Chrome:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://support.google.com/chrome/answer/95647?hl=en'
              >
                https://support.google.com/chrome/answer/95647?hl=en
              </a>
            </li>
            <li>
              <b>Safari:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac'
              >
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              </a>
            </li>
            <li>
              <b>Opera:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://help.opera.com/en/latest/web-preferences/'
              >
                https://help.opera.com/en/latest/web-preferences/
              </a>
            </li>
            <p>
              In cases where you access the website through an iOS or Android
              mobile device, please follow the instructions below to delete or
              block cookies on your device:
            </p>
            <li>
              <b>Android:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://support.google.com/answer/32050'
              >
                https://support.google.com/answer/32050
              </a>
            </li>
            <li>
              <b>iOS:</b>{' '}
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href='https://support.apple.com/en-us/HT201265'
              >
                https://support.apple.com/en-us/HT201265
              </a>
            </li>
          </ul>
          <li>
            <b>FIRST-PARTY COOKIES</b>
            <p>
              We use cookies to enhance the performance of our website and
              personalize your online experience. Cookies help us to collect
              information on how people use our website and which pages they
              visit. They enable us to monitor the number of visitors and to
              analyze website usage patterns and trends. We collect this
              information anonymously, so it does not identify anyone as an
              individual and no personal information is stored in our cookies.
              We always use cookie data in a responsible way.
            </p>
          </li>
          <li>
            <b>THIRD-PARTY COOKIES</b>
            <p>
              Third-party cookies may come from partners or third-party
              companies that provide functional web services or tools for our
              website and the optimal functioning and operation of our services.
              We use third party cookies responsibly and for the sole purpose of
              providing optimal functioning of the platform and services. You
              may opt out of these cookies by following the cookie removal
              information contained in this document or the technical
              information of the browser from which you access our website and
              services
            </p>
          </li>
          <li>
            <b>PURPOSES OF OUR COOKIES</b>
            <p>Our cookies are used for the following purposes:</p>
            <p>
              <b>Necessary:</b> Necessary cookies are essential files that
              enable basic functions on the website, such as navigation and
              security. They do not collect personal information.
            </p>
            <p>
              <b>Functional:</b> We use functional cookies to enhance your
              online experience by remembering user preferences and settings and
              maintaining consistency across the website, without tracking
              activities.
            </p>
            <p>
              <b>Analytics and Performance:</b> These cookies collect
              information about how users interact with our website, including
              the most visited pages, as well as other analytical data. We use
              this data to improve how our website works and to understand how
              users interact with the website.
            </p>
            <p>
              <b>Advertisement:</b> Advertisement cookies are pieces of
              information that websites store on your device when you visit our
              site. These cookies track your online activities and allow us to
              display advertisements tailored to your interests, thus improving
              your browsing experience.
            </p>
          </li>
          <li>
            <b>CONTACT US</b>
            <p>
              If you have questions or concerns about this cookie policy and the
              handling and security of your data, please contact us through our
              contact forms or by using the contact information below:
            </p>
            <p>TrimyLink.</p>
            <p>Email:support@trimylink.io</p>
          </li>
        </ol>
      </Row>

      <Row>
        <Footer />
      </Row>
    </Container>
  );
}
