import React, { useEffect, useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';

interface ColorPickerComponentProps {
  color1: string;
  setColor1: React.Dispatch<React.SetStateAction<string>>;
  color2: string;
  setColor2: React.Dispatch<React.SetStateAction<string>>;
  // disabledButton: boolean;
  textColor: string;
  setTextColor: React.Dispatch<React.SetStateAction<string>>;
}

export function ColorPickerComponent({
  color1,
  setColor1,
  color2,
  setColor2,
}: // disabledButton,
ColorPickerComponentProps) {
  const [selectedOption, setSelectedOption] = useState('background');
  useEffect(() => {
    console.log('color1', color1);
    console.log('color2', color2);
  }, []);

  return (
    <Row className='d-flex align-items-center justify-content-center w-100 mt- pt-3'>
      <Col>
        <Form.Check
          type='radio'
          label='Background color'
          checked={selectedOption === 'background'}
          onChange={() => setSelectedOption('background')}
        />
      </Col>
      <Col>
        <Form.Check
          type='radio'
          label='Gradient background color'
          checked={selectedOption === 'gradient'}
          onChange={() => setSelectedOption('gradient')}
        />
      </Col>

      <Row className='d-flex align-items-center justify-content-center w-100 mt- pt-3'>
        <Form.Control
          type='color'
          className='w-25'
          // disabled={disabledButton}
          value={color1}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setColor1(event.target.value);
            if (selectedOption === 'background') setColor2('');
          }}
        />

        {selectedOption === 'gradient' && (
          <Form.Control
            type='color'
            className='mx-3 w-25'
            // disabled={disabledButton}
            value={color2}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setColor2(event.target.value);
            }}
          />
        )}
      </Row>
    </Row>
  );
}
