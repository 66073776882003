import { set } from 'date-fns';
import { useEffect, useState } from 'react';
import { Modal, Dropdown, Button, Form } from 'react-bootstrap';

export function ProfileLinkEditModal({
  handleShow,
  handleClose,
  show,
  project,
  setSelectedStatus,
  selectedStatus,
  setSuccessText,
  setErrorText,
}: {
  handleShow: any;
  handleClose: any;
  show: any;
  project: any;
  setSelectedStatus: any;
  selectedStatus: any;
  setSuccessText: any;
  setErrorText: any;
}) {
  const [newUrl, setNewUrl] = useState(project?.orginalUrl);
  const [statusChanged, setStatusChanged] = useState(false);
  const [tier, setTier] = useState('');

  useEffect(() => {
    if (project) setNewUrl(project.orginalUrl);
    setTier(localStorage.getItem('tier') || '');
  }, [project]);
  async function updateProject() {
    if (tier.toUpperCase() === 'FREE' && statusChanged) {
      let url;
      if (selectedStatus.toUpperCase() === 'ACTIVE') {
        url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/${project.shortid}/activate`;
      } else {
        url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/${project.shortid}/deactivate`;
      }
      const requestId = Date.now().toString();
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
      });
      if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
        }
        setSuccessText('');
      } else {
        setErrorText('');
        setSuccessText('Project updated successfully');
        project.active = selectedStatus === 'Active' ? true : false;
      }
      handleClose();
      return;
    }

    const requestId = Date.now().toString();
    const response = await fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/common/update-url`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
        body: JSON.stringify({
          shortId: project.shortid,
          orginalUrl: newUrl,
          active: selectedStatus === 'Active' ? true : false,
        }),
      }
    );
    if (!response.ok) {
      if (response.status === 400) {
        const error = (await response.json()).message;
        setErrorText(error);
      } else {
        setErrorText(
          `Ops something went wrong at our end -- Reference Number: ${requestId}`
        );
      }
      setSuccessText('');
    } else {
      setErrorText('');
      setSuccessText('Project updated successfully');
      project.orginalUrl = newUrl;
      project.active = selectedStatus === 'Active' ? true : false;
    }

    handleClose();
  }

  return (
    <>
      {project && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Updaing {project.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId='formBasicOrginalUrl'>
                <Form.Label className='mt-2'>Orginal Url</Form.Label>
                <Form.Control
                  type='text'
                  value={newUrl}
                  onChange={(e) => {
                    setNewUrl(e.target.value);
                  }}
                />
              </Form.Group>

              <Dropdown className='mt-3'>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  Status
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    active={selectedStatus === 'Active'}
                    onClick={() => {
                      setSelectedStatus('Active');
                      setStatusChanged(true);
                    }}
                  >
                    Active
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={selectedStatus === 'Inactive'}
                    onClick={() => {
                      setSelectedStatus('Inactive');
                      setStatusChanged(true);
                    }}
                    className='mt-1'
                  >
                    Inactive
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form>
          </Modal.Body>
          <Modal.Footer className='py-3'>
            <Button variant='danger' onClick={handleClose}>
              Close
            </Button>
            <Button variant='success' onClick={updateProject}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
