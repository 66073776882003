import { GoogleLogin } from '@react-oauth/google';
import refreshToken from './RefreshToken';
import { useNavigate } from 'react-router';
interface GoogleLoginComponentProps {
  setErrorText: any;
  setShow?: (show: boolean) => void;
}
export function GoogleLoginComponent({
  googleLoginComponentProps,
}: {
  googleLoginComponentProps: GoogleLoginComponentProps;
}) {
  const navigate = useNavigate();
  async function handleResponse(response: any) {
    const requestId = Date.now().toString();
    const apiResponse = await fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/verify-google-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
        },
        body: JSON.stringify({ accessToken: response.credential }),
      }
    );
    if (!apiResponse.ok) {
      googleLoginComponentProps.setErrorText('error while login with google');
    } else {
      if (googleLoginComponentProps.setShow)
        googleLoginComponentProps.setShow(false);
      googleLoginComponentProps.setErrorText('');
      const data = await apiResponse.json();
      googleLoginComponentProps.setErrorText('');
      localStorage.setItem('jwtToken', data.jwtToken);
      localStorage.setItem('tier', data.tier);
      localStorage.setItem('refreshToken', data.refreshToken);

      const expiresInMilliseconds = data.expiresIn * 1000;
      localStorage.setItem(
        'expiresAt',
        (Date.now() + expiresInMilliseconds).toString()
      );
      setTimeout(refreshToken, expiresInMilliseconds - 5000);
      window.location.href = '/';
    }
  }
  const onSuccess = (response: any) => {
    handleResponse(response);
  };

  function onError(): void {
    googleLoginComponentProps.setErrorText(`error while login with google2`);
  }

  return (
    <div className='d-flex justify-content-center'>
      <GoogleLogin
        text='continue_with'
        onSuccess={onSuccess}
        onError={onError}
        size='large'
        width={300}
      />
    </div>
  );
}
