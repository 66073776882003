import { Container, Row, Col } from 'react-bootstrap';
import './../../App.css';
import { Sidebar } from './../profile/Sidebar';
import Header from '../home-page/Header';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountInfo } from './AccountInfo';
import { ProfileLinks } from './../profile/ProfileLinks';
import { ProfileQR } from './../profile/ProfileQR';
import { LinkInBioProfile } from './bio/LinkInBioProfile';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';
import { Analytics } from './analytics/Analytics';
import { ManageSubscription } from './subscription/ManageSubscription';
import { ApiKeysManagement } from './ApiKeysManagement';
import { BulkShortening } from './BulkShortening';
export function Profile() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('info');
  const [key, setKey] = useState(Math.random());
  const location = useLocation();
  const trimyCtx = useContext(TrimyLinkContext);
  useEffect(() => {
    const state = location.state;
    console.log('selectedItem:', state);
    if (state && state.selectedTab) {
      setSelectedItem(state.selectedTab);
    }
    if (!localStorage.getItem('jwtToken')) {
      navigate('/');
    }
  }, [trimyCtx]);
  return (
    <div>
      <Row>
        <Header />
      </Row>
      <Row className='mx-0'>
        <Col xs={5} sm={5} md={3}>
          <Sidebar
            onItemClick={(item) => {
              setSelectedItem(item);
              setKey(Math.random());
            }}
          />
        </Col>
        <Col xs={7} sm={7} md={9}>
          <Row
            className='mt-5 py-5 px-0'
            style={{ backgroundColor: 'transparent' }}
            id='id1'
          >
            {selectedItem === 'info' && <AccountInfo key={selectedItem} />}
            {selectedItem === 'links' && <ProfileLinks key={selectedItem} />}
            {selectedItem === 'qr' && <ProfileQR key={selectedItem} />}
            {selectedItem === 'linkInBio' && (
              <LinkInBioProfile key={selectedItem} />
            )}
            {selectedItem === 'analytics' && <Analytics key={selectedItem} />}
            {selectedItem === 'manageSubscription' && (
              <ManageSubscription key={selectedItem} />
            )}

            {selectedItem === 'api-keys' && (
              <ApiKeysManagement key={selectedItem} />
            )}

            {selectedItem === 'bulk-shortening' && (
              <BulkShortening key={selectedItem} />
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
