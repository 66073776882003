import { useContext, useEffect, useRef, useState } from 'react';
import { FAQ } from './FAQ';
import { Footer } from './Footer';
import { GenerateBox } from './GenerateBox';
import { GetStartedHero } from './GetStartedHero';
import Header from './Header';
import Hero from './Hero';
import { HowItWorks } from './HowItWorks';
import { Products } from '../products/Products';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';
import { Alert, Row } from 'react-bootstrap';

export function MainPage() {
  const trimxCtx = useContext(TrimyLinkContext);
  const [showBanner, setShowBanner] = useState(true);
  const [promoText, setPromoText] = useState(
    '🎉 Get Your First Month Free on Premium Subscription! 🎉 use code 𝐏𝐑𝐄𝐌𝐈𝐔𝐌𝟏𝟎𝟎 at checkout.🎉'
  );

  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      const jwtToken = localStorage.getItem('jwtToken');
      if (jwtToken) {
        const expirtesAt = localStorage.getItem('expiresAt');
        if (expirtesAt && Date.now() > Number(expirtesAt)) {
          localStorage.clear();
          trimxCtx.setGetStartedGenerateButtonText('Get Started For Free');
          trimxCtx.setLoginLogOutButtonText('Login');
          trimxCtx.setSignUpProfileButtonText('Sign Up For Free');
          setShowBanner(true);
        } else {
          if (
            localStorage.getItem('tier') &&
            localStorage.getItem('tier') === 'Free'
          ) {
            setPromoText(
              '🎉 Upgrade now and use code 𝐖𝐄𝐋𝐂𝐎𝐌𝐄𝟏𝟎𝟎 at checkout.🎉'
            );
            setShowBanner(true);
            return;
          }
          setShowBanner(false);
        }
      } else {
        setShowBanner(true);
      }
    }
  }, [trimxCtx]);

  const generateBoxRef = useRef(null);
  return (
    <>
      <Header />
      {showBanner && (
        <Row>
          <Alert
            variant='success'
            onClose={() => setShowBanner(false)}
            className='w-100 justify-content-center text-center '
            dismissible
          >
            <Alert.Heading>
              🎉 Get Your First Month Free on Premium Subscription! 🎉
            </Alert.Heading>
            <p>{promoText}</p>
          </Alert>
        </Row>
      )}

      <Hero ref={generateBoxRef} />
      <GenerateBox />
      <Products />
      <HowItWorks />

      <FAQ />
      <GetStartedHero resultRef={generateBoxRef} />

      <Footer />
    </>
  );
}
