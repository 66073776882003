import { Dropdown, FormControl, Form, Row } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

export function AnalyticsUrlsDropDown({
  errorText,
  setErrorText,
  dropDownText,
  fetchUrls,
  fetchQRs,
}: {
  errorText: string;
  setErrorText: any;
  dropDownText: string;
  fetchUrls: boolean;
  fetchQRs: boolean;
}) {
  const [search, setSearch] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState<any>([]);
  const [showError, setShowError] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(true);

  const [fetchUrl, setFetchUrl] = useState('');

  useEffect(() => {
    fetchItems(offset);
    if (fetchUrls)
      setFetchUrl(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/urls?limit=10&offset=`
      );

    if (fetchQRs)
      setFetchUrl(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/qr/qrs?limit=10&offset=`
      );
  }, [offset, fetchUrl]);

  const fetchItems = async (page: number) => {
    const newItems = await fetchLinks(page);
    if (newItems.length === 0) return;
    setItems((prevItems: any[]) => [...prevItems, ...newItems]);
  };

  async function fetchLinks(page: number): Promise<any[]> {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(`${fetchUrl}${offset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
      });
      if (!response.ok) {
        setIsLoadMore(false);
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      } else {
        const data = await response.json();
        setErrorText('');
        setShowError(false);

        if (data.data.length == 0) setIsLoadMore(false);
        else setIsLoadMore(true);
        return data.data;
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId}`
      );
      setShowError(true);
      return [];
    }
    return [];
  }
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  console.log('search: ', search);
  console.log('items: ', items);
  const filteredItems = items.filter(
    (item: any) =>
      search && item.name.toUpperCase().includes(search?.toUpperCase())
  );

  const handleSelect = (selectedItem: any) => {
    setSelectedItems((prevState: any) => {
      if (prevState.includes(selectedItem)) {
        return prevState.filter((item: any) => item !== selectedItem);
      } else {
        return [...prevState, selectedItem];
      }
    });
  };

  return (
    <div className='d-flex center align-items-center justify-content-center  mt-3 mx-sm-5 mx-3 '>
      <Dropdown onToggle={isOpen ? handleClose : handleOpen}>
        <Dropdown.Toggle variant='outline-success' id='dropdown-basic'>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip id='tooltip-right'>
                search box searches only on Loaded items in the list
              </Tooltip>
            }
          >
            <HelpCircle className='mx-2 mt-0' />
          </OverlayTrigger>
          {dropDownText}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <FormControl
            autoFocus
            placeholder='Type a name to filter...'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          {filteredItems.map((item: any) => (
            <Dropdown.Item
              key={item.name}
              onClick={(e) => {
                e.stopPropagation();
                handleSelect(item.name);
              }}
            >
              <Form.Check
                type='checkbox'
                label={item.name}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelect(item.name);
                }}
                checked={selectedItems.includes(item.name)}
              />
            </Dropdown.Item>
          ))}

          {isLoadMore && filteredItems.length >= 10 && (
            <Dropdown.Item
              key='loadMore'
              onClick={(e) => {
                e.stopPropagation();
                setOffset(offset + 10);
              }}
            >
              <a>Load More...</a>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </div>
  );
}
