import { Container, Row } from 'react-bootstrap';
import Header from './home-page/Header';
import { Footer } from './home-page/Footer';

export function TermsAndConditions() {
  return (
    <Container className='mx-3 mt-5 px-3 '>
      <Row>
        <Header />
      </Row>

      <Row>
        <h3>
          <b>TERMS AND CONDITIONS</b>
        </h3>
      </Row>

      <Row className='mt-5'>
        <h4>
          <b>Welcome to TrimyLink!</b>
        </h4>
        <p>hese are the terms and conditions for: </p>
        <ul>
          <li>
            <a
              style={{ color: 'blue', textDecoration: 'underline' }}
              href='https://trimylink.io'
            >
              https://trimylink.io
            </a>
          </li>
        </ul>
        <p>
          The following terms and conditions apply to your use of the platform.
          This includes mobile and tablet versions, as well as any other version
          of TrimyLink accessible via desktop, mobile, tablet, social media or
          other devices.
        </p>
        <p>
          By using the platform, you agree to these terms and conditions and our
          privacy policy. In these terms and conditions, the words "platform"
          refer to the TrimyLink website and the set of services offered by
          TrimyLink, "we", "us", "our", and "TrimyLink" refers to TrimyLink and
          "you", and "user", refers to you, the user of TrimyLink.
        </p>
        <p>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING ANY
          OF OUR SERVICES.
        </p>
      </Row>

      <Row>
        <ol>
          <li>
            <b>ACCEPTANCE OF TERMS</b>
            <p>
              By using the platform, you agree to be bound by this agreement. If
              you do not agree to the terms of this agreement, you must not use
              our services and our platform. We may modify this agreement from
              time to time, and such modification will be effective upon posting
              on the platform. You agree to be bound by any modifications to
              these terms and conditions when you use TrimyLink following the
              posting of such modification; therefore, it is important that you
              review this agreement regularly.
            </p>
            <p>
              By using the platform, you represent and warrant that you have the
              full right, power and authority to enter into this agreement and
              to fully perform all of your obligations hereunder. You further
              represent and warrant that you are not under any legal incapacity
              or contractual restriction that would prevent you from entering
              into this agreement.
            </p>
          </li>
          <li>
            <b>NOTIFICATIONS </b>
            <p>
              By providing your email address to TrimyLink, you agree that we
              may use your email address to send you notifications about
              TrimyLink. We may also use your email address to send you
              notifications and other messages, such as changes to service
              features, news and special content. If you do not wish to receive
              these emails, you may opt-out of receiving them by submitting your
              unsubscribe request through the contact information or by using
              the "unsubscribe" option in the emails. Opting out may prevent you
              from receiving notifications and mailings about updates, news or
              special content.
            </p>
          </li>
          <li>
            <b>ACCOUNT </b>
            <p>
              Users can register and open an account on the platform by
              completing the registration form available on the platform or by
              registering through their Google accounts. You are responsible for
              maintaining the confidentiality of your password and account
              information, and are fully responsible for all activities that
              occur under your password or account. You agree to immediately
              notify TrimyLink of any unauthorized use of your password or
              account or any other breach of security, and to ensure that you
              log out of your account at the end of each session. You may never
              use another user's account without prior authorization from
              TrimyLink. TrimyLink will not be liable for any loss or damage
              arising from your failure to comply with this agreement.{' '}
            </p>
            <p>
              Users may cancel their accounts at any time and for any reason
              through the account settings or by sending us their request
              through our contact information. Such cancellation will only
              result in the deletion of the account and the deletion of all
              personal data provided to TrimyLink.
            </p>
            <p>
              {' '}
              TrimyLink reserves the right to terminate your account or your
              access immediately, with or without notice, and without any
              liability to you, if TrimyLink considers that you have violated
              any of the provisions contained in these terms and conditions.
            </p>
          </li>

          <li>
            <b>SERVICES</b>
            <p>
              TrimyLink offers a variety of digital services designed to improve
              the efficiency of online link management and distribution. These
              services include the ability to shorten URLs, generate custom QR
              codes and simplify link management through an intuitive and
              user-friendly platform.
            </p>
            <p>
              Users can shorten any long URL into a shorter, more manageable
              link, which is ideal for sharing on social networks, emails and
              other digital media. In addition, TrimyLink provides tools to
              generate QR codes that can be customized and used to direct users
              to specific web pages instantly and securely.
            </p>
            <p>
              The platform also offers advanced link management solutions,
              allowing users to organize, modify and analyze the traffic of
              their shortened links. These tools are designed to provide users
              with complete control over their links, making it easy to monitor
              their performance and optimize their digital marketing strategies.
            </p>
            <p>
              TrimyLink is committed to maintaining a high level of service and
              continually updating its functionality to adapt to the changing
              needs of its users. However, the availability and functionality of
              the services may be subject to temporary interruptions due to
              scheduled maintenance, system upgrades or external factors beyond
              TrimyLink's control.
            </p>
            <p>
              By using TrimyLink's services, the user agrees to comply with all
              applicable usage policies and agrees not to use the services for
              illegal, deceptive or malicious activities. TrimyLink reserves the
              right to modify, suspend or discontinue any of its services with
              or without notice to the user, as necessary for the operation and
              improvement of the platform
            </p>
          </li>

          <li>
            <b>SUBSCRIPTIONS</b>
            <p>TrimyLink offers the following subscription plans:</p>
            <ul>
              <li>Free</li>
              <li>Basic</li>
              <li>Standard</li>
              <li>Premium</li>
            </ul>
            <p>
              Users will be able to use the platform through a free subscription
              or paid subscriptions that will allow the user to access special
              functionalities on the platform. When a user purchases a
              subscription, TrimyLink will send a confirmation email. This
              confirmation email will be produced automatically so that the user
              will have confirmation of the payment and the start of the
              subscription. If the user does not receive the email confirmation
              of the purchase and the start of the subscription, it is possible
              that it has been sent to your spam folder.
            </p>
            <p>
              Subscriptions include monthly automatic billing payments. You
              authorize TrimyLink to renew your subscription and to charge you
              periodically and progressively on each billing date. The
              subscription billing date is the date on which you purchase the
              subscription and make the first payment. On the corresponding
              billing date, you will automatically be charged the corresponding
              subscription fee. The subscription will remain active until you
              cancel it or we terminate it. You must cancel your subscription
              before it renews to avoid the next billing period. We will bill
              you for the subscription billing fee in the payment method you
              choose during registration and subscription purchase.
            </p>
            <p>
              Subscriptions will automatically renew for an additional period
              unless cancelled prior to the next billing period. To cancel
              subscriptions, users must can cancel their subscription from their
              profile option or submit a cancellation request through our
              contact information and the subscription will be cancelled for the
              next billing period. If a subscription is cancelled, the user may
              continue to use the website payment features for the subscription
              until the next billing period, at which time the subscription be
              cancelled and subscription downgraded to Free tier.
            </p>
            <p>
              TrimyLink may change or discontinue the availability of
              subscriptions at any time at its sole discretion. If a
              subscription purchase is cancelled, the payment made for the
              subscription will be refunded for the applicable billing period.
              This does not affect your statutory rights.
            </p>
          </li>

          <li>
            <b>PAYMENTS</b>
            <p>
              Users will be able to pay for subscriptions through the following
              payment methods:
            </p>
            <ul>
              <li>
                Credit/debit card (Visa, Mastercard, Discover, Amex, Diners,
                etc.){' '}
              </li>
              <li>Apple Pay</li>
              <li>Google Pay</li>
            </ul>
            <p>
              With the exception of the free subscription, the subscription
              payment will be charged to your credit or debit card upon
              completion of the subscription payment and registration process.
              The subscription will be activated upon completion of the payment
              and registration process and will be charged on each subscription
              billing date automatically. Once the transaction is processed, we
              will send an electronic receipt to the user's email address.{' '}
            </p>
            <p>
              If you find any inconsistencies in your billing, please contact us
              via our contact details or you can make a complaint via the
              customer service of the relevant payment processor.
            </p>
            <p>
              If your card is declined, you will receive an error message. No
              payment will be charged to your card and no order will be
              processed. There may be a pending transaction on your account
              until your card issuing bank withdraws the authorization. This
              usually takes 2 to 5 working days. Your card may be declined for a
              number of reasons, such as insufficient funds, AVS (Address
              Verification System) mismatch or you have entered an incorrect
              security code.
            </p>
            <p>
              If your payment is declined, you will need to provide an
              alternative payment method or provide another card on which the
              payment can be charged and processed.
            </p>
            <p>
              Your payment details will be treated and retained securely and for
              the sole purpose of processing the purchase of the subscriptions.
              TrimyLink reserves the right to engage any payment processor
              available.
            </p>
          </li>

          <li>
            <b>LIMITATION OF LIABILITY</b>
            <p>
              In using the services offered by TrimyLink, including, but not
              limited to, the functionality to shorten URLs, generate QR codes
              and simplify link management, the user agrees that TrimyLink acts
              solely as a facilitator for efficient link management and is not
              responsible for the activities performed by the user or the
              content accessed through the links generated on the platform.
              TrimyLink does not guarantee the accuracy, availability or
              relevance of such links or the content accessible through them.
            </p>
            <p>
              them. The user acknowledges that the responsibility for verifying
              the safety and legality of the linked content rests entirely with
              the user. TrimyLink disclaims any legal liability for misuse of
              the Services, including, but not limited to, linking to illegal,
              copyright infringing, or any other content that violates
              applicable laws.
            </p>
            <p>
              TrimyLink shall also not be liable for any direct, indirect,
              incidental, special, consequential or punitive damages, including,
              but not limited to, loss of profits, goodwill, use, data or other
              intangible losses, resulting from the use or inability to use the
              services offered.
            </p>
            <p>
              This limitation of liability applies regardless of whether the
              loss or damage is caused by acts of nature, technical failures,
              human error, or malicious interventions affecting the operability
              and security of the platform. The User agrees that the use of the
              Services is at the User's own risk and responsibility.
            </p>
            <p>
              In no event shall TrimyLink be liable to the user or any third
              party for any modification, suspension or interruption of the
              services. This limitation of liability clause is designed to apply
              to the maximum extent permitted by law.
            </p>
          </li>

          <li>
            <b> LICENSE TO USE THE PLATFORM</b>
            <p>
              TrimyLink grants you a personal, worldwide, royalty-free,
              non-assignable and non-exclusive license to use the TrimyLink
              platform, including but not limited to modified versions, updates,
              upgrades, enhancements, improvements, additions, additions and
              copies, if any. This license is for the sole purpose of allowing
              you to use the features available on the platform in the manner
              permitted by these terms. You may not copy, modify, distribute,
              sell or lease any part of our platform or the included software,
              nor may you reverse engineer or attempt to extract the source code
              of such software, unless such restrictions are prohibited by law,
              or unless you have our written permission.
            </p>
            <p>
              The user agrees not to use the platform in a negligent, fraudulent
              or unlawful manner. It also undertakes not to carry out any
              conduct or action that could damage the image, interests or rights
              of TrimyLink or third parties. TrimyLink reserves the right to
              terminate the user's access immediately, with or without notice,
              and without liability to the user, if TrimyLink considers that the
              user has violated any of these terms.
            </p>
          </li>

          <li>
            <b>COPYRIGHT</b>
            <p>
              All the content available on the platform, including, without
              limitation, names, logos, trademarks, images, text, columns,
              graphics, videos, photographs, illustrations, software and other
              elements are protected by copyrights, trademarks and/or other
              intellectual property rights owned and controlled by TrimyLink or
              by third parties that have licensed or otherwise provided their
              material to the platform. You acknowledge and agree that all
              Materials on TrimyLink are made available for limited,
              non-commercial, personal use only. Except as specifically provided
              herein. No material may be copied, reproduced, republished, sold,
              downloaded, posted, transmitted, or distributed in any way, or
              otherwise used for any purpose, by any person or entity, without
              TrimyLink prior express written permission. You may not add,
              delete, distort, or otherwise modify the material. Any
              unauthorized attempt to modify any material, to defeat or
              circumvent any security features, or to utilize TrimyLink or any
              part of the material for any purpose other than its intended
              purposes is strictly prohibited.
            </p>
          </li>

          <li>
            <b>COPYRIGHT INFRINGEMENT </b>
            <p>
              TrimyLink will respond to all inquiries, complaints and claims
              relating to alleged infringement by breach or violation of the
              provisions contained in national and international copyright and
              intellectual property laws and regulations. TrimyLink respects the
              intellectual property of others and expects users to do the same.
              If you believe, in good faith, that any material provided on the
              platform infringes your copyright or other intellectual property
              rights, please submit your request using our contact information,
              with the following information:{' '}
            </p>
            <ul>
              <li>
                Identification of the intellectual property right that is
                allegedly infringed. All relevant registration numbers or a
                statement regarding ownership of the work should be included.
              </li>
              <li>
                A statement specifically identifying the location of the
                infringing material.{' '}
              </li>
              <li>Your name, address, telephone number and email address.</li>
              <li>
                A statement by you that you have a good faith belief that the
                use of the allegedly infringing material is not authorized by
                the copyright owner, or its agents, or by law
              </li>
              <li>
                A statement by you, made under penalty of perjury, that the
                information in your notification is accurate, and that you are
                the copyright owner or authorized to act on its behalf.
              </li>
              <li>
                An electronic or physical signature of the copyright owner or of
                the person authorized to act on the copyright owner's behalf.
              </li>
            </ul>
          </li>

          <li>
            <b>PROHIBITED ACTIVITIES</b>
            <p>The following activities are prohibited:</p>
            <ul>
              <li>
                Access, monitor, reproduce, distribute, transmit, broadcast,
                display, sell, license, copy or otherwise exploit any content of
                the services, including but not limited to, using any robot,
                spider, scraper or other automated means or any manual process
                for any purpose not in accordance with this agreement or without
                our express written permission.
              </li>
              <li>
                Violate the restrictions in any robot exclusion headers on the
                services or bypass or circumvent other measures employed to
                prevent or limit access to the services.
              </li>
              <li>
                Take any action that imposes, or may impose, in our discretion,
                an unreasonable or disproportionately large load on our
                infrastructure.
              </li>
              <li>
                Deep-link to any portion of the services for any purpose without
                our express written permission.
              </li>
              <li>
                "Frame", "mirror" or otherwise incorporate any part of the
                platform into any other platform or service without our prior
                written authorization.
              </li>
              <li>
                Attempt to modify, translate, adapt, edit, decompile,
                disassemble or reverse engineer any software program used by
                TrimyLink.
              </li>
              <li>
                Circumvent, disable or otherwise interfere with security-related
                features of the platform or features that prevent or restrict
                use or copying of any content.{' '}
              </li>
            </ul>
          </li>

          <li>
            <b>DISCLAIMER OF WARRANTIES </b>
            <p>
              Except as provided above we can give no other warranties,
              conditions or other terms, express or implied, statutory or
              otherwise and all such terms are hereby excluded to the maximum
              extent permitted by law.
            </p>
            <p>
              You will be responsible for any breach of these terms by you and
              if you use the platform in breach of these terms you will be
              liable to and will reimburse TrimyLink for any loss or damage
              caused as a result.
            </p>
            <p>
              TrimyLink shall not be liable for any amount for failure to
              perform any obligation under this Agreement if such failure is due
              to the occurrence of any unforeseen event beyond its reasonable
              control, including, without limitation, Internet outages,
              communications outages, fire, flood, or any uncontrollable act of
              nature.
            </p>
            <p>
              Subject as aforesaid, to the maximum extent permitted by law,
              TrimyLink excludes liability for any loss or damage of any kind
              howsoever arising, including without limitation any direct,
              indirect or consequential loss whether or not such arises out of
              any problem you notify to TrimyLink and TrimyLink shall have no
              liability to pay any money by way of compensation, including
              without limitation all liability in relation to:
            </p>
            <ul>
              <li>Any incorrect or inaccurate information on the platform.</li>
              <li>
                The infringement by any person of any Intellectual property
                rights of any third party caused by their use of the platform.
              </li>
              <li>
                Any loss or damage resulting from your use or the inability to
                use the platform or resulting from unauthorized access to, or
                alteration of your transmissions or data in circumstances which
                are beyond our control.
              </li>
              <li>
                Any loss of profit, wasted expenditure, corruption or
                destruction of data or any other loss which does not directly
                result from something we have done wrong.
              </li>
              <li>
                Any amount or kind of loss or damage due to viruses or other
                malicious software that may infect a user's computer equipment,
                software, data or other property caused by persons accessing or
                using content from the platform or from transmissions via emails
                or attachments received from TrimyLink.
              </li>
              <li>
                All representations, warranties, conditions and other terms
                which but for this notice would have effect.
              </li>
            </ul>
          </li>

          <li>
            <b>ELECTRONIC COMMUNICATIONS</b>
            <p>
              TrimyLink will not accept any responsibility for failed, partial
              or garbled computer transmissions, for any breakdown, failure,
              connection or availability of computer, telephone, network,
              electronic or Internet hardware or software, for Internet
              accessibility or availability or traffic congestion or for any
              unauthorized human acts, including any errors or mistakes.
            </p>
          </li>

          <li>
            <b>INDEMNIFICATION</b>
            <p>
              You agree to defend and indemnify TrimyLink from and against any
              claims, causes of action, demands, recoveries, losses, damages,
              fines, penalties or other costs or expenses of any kind or nature
              including but not limited to reasonable legal and accounting fees,
              brought by third parties as a result of:
            </p>
            <ul>
              <li>
                Your breach of this agreement or the documents referenced
                herein.
              </li>
              <li>Your violation of any law or the rights of a third party.</li>
              <li>Your use of the TrimyLink platform.</li>
            </ul>
          </li>

          <li>
            <b>CHANGES </b>
            <p>
              We may change the platform and these terms at any time, in our
              sole discretion and without notice to you. You are responsible for
              remaining knowledgeable about these terms. Your continued use of
              the platform constitutes your acceptance of any changes to these
              terms and any changes will supersede all previous versions of the
              terms. Unless otherwise specified herein, all changes to these
              terms apply to all users take effect.{' '}
            </p>
          </li>

          <li>
            <b>INTEGRATION CLAUSE</b>
            <p>
              This agreement together with the privacy policy and any other
              legal notices published by TrimyLink, shall constitute the entire
              agreement between you and TrimyLink concerning and governs your
              use of the platform.
            </p>
          </li>

          <li>
            <b>SEVERABILITY</b>
            <p>
              If any section of these terms is held invalid, illegal or
              unenforceable, the validity, legality and enforceability of the
              remaining provisions shall not in any way be affected or impaired.
              Our failure to enforce or delay in enforcing any provision of
              these terms at any time does not waive our right to enforce the
              same or any other provision in the future.
            </p>
          </li>

          <li>
            <b> FORCE MAJEURE</b>
            <p>
              TrimyLink shall not be liable for any failure due to causes beyond
              its reasonable control, including but not limited to acts of civil
              authorities, acts of military authorities, riots, acts of nature
              and natural disasters, and other acts which may be due to
              unforeseen circumstances.{' '}
            </p>
          </li>

          <li>
            <b>TERMINATION</b>
            <p>
              oth the user and TrimyLink acknowledge that failure to comply with
              these terms and conditions may result in termination of the
              agreement between both parties.
            </p>
            <ul>
              <li>
                <b> In case of non-compliance by the user:</b> If the user fails
                to comply with any of the terms set forth in these terms and
                conditions, TrimyLink reserves the right to unilaterally
                terminate the user's access to and use of the platform.
                TrimyLink may take this action without prior notice, and the
                user will automatically lose all rights to access and use the
                platform. Furthermore, TrimyLink shall not be liable for any
                loss of data or information associated with the user's account.
              </li>
              <li>
                <b>In case of non-compliance by TrimyLink:</b> If TrimyLink
                fails to comply with its obligations under the agreed terms and
                conditions, the user shall be entitled to terminate the
                agreement and stop using the platform. The user shall notify
                TrimyLink in writing of the breach and allow a reasonable time
                for TrimyLink to remedy the situation. If TrimyLink does not
                remedy the breach within the set period, the user may proceed
                with the termination of the agreement.
              </li>
            </ul>
            <p>
              Termination of the agreement shall not release either party from
              any obligations and liabilities that have arisen prior to the date
              of termination.{' '}
            </p>
            <p>
              Continued use of the platform after termination of the agreement
              by either party shall imply acceptance of the conditions set forth
              in the terms and conditions in effect at that time.{' '}
            </p>
          </li>
          <li>
            <b>DISPUTES</b>
            <p>
              You acknowledge and agree that any dispute, claim or controversy
              arising out of or relating to these terms and conditions, whether
              for breach, termination, enforcement, interpretation or validity,
              as well as any aspect of the use of the platform, shall preferably
              be resolved by binding arbitration between you and TrimyLink.
              However, the right of both parties to bring individual claims
              before a court of competent jurisdiction if deemed necessary
              remains.
            </p>
            <p>
              In the event of any dispute arising in connection with the use of
              the platform or breach of these terms and conditions, both parties
              agree to seek a solution through an arbitration process. This
              process will be carried out before a recognized and respected
              arbitration entity, selected by mutual agreement of both parties
              and following the applicable commercial arbitration rules.
            </p>
            <p>
              In addition, the user agrees, to the extent permitted by
              applicable law, not to initiate, join or participate in class
              action lawsuits associated with any claim, dispute or controversy
              that may arise in connection with the use of the platform. This
              commitment seeks to promote a more agile and personalized
              resolution of any conflict, favoring arbitration and individual
              legal actions over collective proceedings.
            </p>
          </li>

          <li>
            <b>GOVERNING LAW AND JURISDICTION</b>
            <p>
              Although the platform and services will be available
              internationally, these terms and conditions shall be governed by
              and construed in accordance with the laws of the United Arab
              Emirates. Any dispute relating to these terms shall be subject to
              the exclusive jurisdiction of Dubai, United Arab Emirates. This
              applies unless binding arbitration is agreed to under the
              applicable section.
            </p>
          </li>

          <li>
            <b> FINAL PROVISIONS</b>
            <p>
              The use of our platform and the services offered by TrimyLink is
              conditioned upon your acceptance of and compliance with all of the
              terms and conditions set forth. This authorization to use our
              services does not extend to jurisdictions where these provisions
              are not respected or enforced.
            </p>
            <p>
              Our commitment to the enforcement of these terms is strictly
              governed by applicable laws and legal process. Importantly, these
              terms do not restrict our ability to comply with legal or
              governmental requirements, including, but not limited to, those
              related to law enforcement and the use of our platform.
              Information provided or collected in connection with the use of
              the platform will be subject to these requirements.
            </p>
            <p>
              In the event that any provision of these terms is held invalid,
              illegal or unenforceable by a court or competent authority, such
              decision shall not affect the validity or enforceability of the
              remaining provisions. The failure or delay in enforcing any of
              these terms by us at any time shall not constitute a waiver of our
              rights to enforce such provision, or any other provision, in the
              future.
            </p>
            <p>
              We reserve all rights not expressly granted herein, while
              maintaining the protection of and respect for our intellectual
              property rights and prerogatives at all times.
            </p>
          </li>

          <li>
            <b>CONTACT INFORMATION</b>
            <p>
              If you have questions or concerns about this privacy policy and
              the treatment and security of your data, please contact us through
              our contact forms or by using the following contact information:
            </p>
            <b>TrimyLink.</b>
            <b>Email: support@trimylink.io</b>
          </li>
        </ol>
      </Row>

      <Row>
        <Footer />
      </Row>
    </Container>
  );
}
