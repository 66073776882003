/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';
import { ErrorTextModal } from '../popup/ErrorTextModal';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';

export function ApiKeysManagement() {
  const [tier, setTier] = useState<string>('');
  const navigate = useNavigate();
  const trimyCtx = useContext(TrimyLinkContext);
  const [apiKey, setApiKey] = useState<string>('');
  const [testApiKey, setTestApiKey] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [oneTimeApiKey, setOneTimeApiKey] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);

  async function fetchApiKey() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/apikey`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
        }
      );
      const data = await response.json();
      if (response.status === 404) {
        setApiKey('');
        setTestApiKey('');
      } else if (response.ok) {
        if (data.productionKey) setApiKey(data?.productionKey);
        if (data.testingKey) setTestApiKey(data?.testingKey);
      } else {
        setErrorText(data.message || 'Error while fetching api keys');
      }
    } catch (error) {
      setErrorText(
        `Error while fetching api keys: --- ReferenceNumber: ${requestId}`
      );
    }
  }
  async function createApiKey() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/apikey `,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ mode: 'production' }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setApiKey(data.maskedApiKey);
        setOneTimeApiKey(data.apiKey);
      } else {
        setErrorText(
          data.message ||
            `Error while creating api keys --- Reference Number : ${requestId}`
        );
      }
    } catch (error) {
      setErrorText(
        `Error while creating api keys: --- ReferenceNumber: ${requestId}`
      );
    }
  }

  async function deleteApiKey() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/apikey`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ mode: 'production' }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setApiKey('');
      } else {
        setErrorText(
          data.message ||
            `Error while deleting api keys --- Reference Number : ${requestId}`
        );
      }
    } catch (error) {
      setErrorText(
        `Error while deleting api keys: --- ReferenceNumber: ${requestId}`
      );
    }
  }

  async function createTestApiKey() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/apikey`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ mode: 'test' }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setTestApiKey(data.maskedApiKey);
        setOneTimeApiKey(data.apiKey);
      } else {
        setErrorText(
          data.message ||
            `Error while creating api keys --- Reference Number : ${requestId}`
        );
      }
    } catch (error) {
      setErrorText(
        `Error while creating api keys: --- ReferenceNumber: ${requestId}`
      );
    }
  }

  async function deleteTestApiKey() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/apikey`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ mode: 'test' }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setTestApiKey('');
      } else {
        setErrorText(
          data.message ||
            `Error while deleting api keys --- Reference Number : ${requestId}`
        );
      }
    } catch (error) {
      setErrorText(
        `Error while deleting api keys: --- ReferenceNumber: ${requestId}`
      );
    }
  }

  useEffect(() => {
    setTier(localStorage.getItem('tier') || '');
    fetchApiKey();
  }, [apiKey, testApiKey, tier]);

  const handleCopy = () => {
    setIsCopied(true);
    console.log('copied');
    setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <>
      {tier.toUpperCase() === 'FREE' ? (
        <div>
          <p className='text-danger'>
            Api Keys are not allowed for Free Plan ,
            <a
              style={{ color: '#2A4978', cursor: 'pointer' }}
              onClick={(e: any) => {
                e.preventDefault();
                console.log('clicked');
                trimyCtx.setSelectedProfileTab(Date.now().toString());
                navigate('/profile', {
                  state: { selectedTab: 'manageSubscription' },
                });
              }}
            >
              <b>Upgrade to paid subscription ?</b>
            </a>
          </p>
        </div>
      ) : (
        <>
          <h3>Production API Key</h3>
          {apiKey === '' ? (
            <Card className='w-100'>
              <Card.Body>
                <Card.Title>API Key</Card.Title>
                <Card.Body>
                  <Row className='mb-3'>
                    <Col xs={12}>
                      <p>API key not generated yet</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='d-flex justify-content-end'>
                      <Button
                        variant='primary'
                        className='me-3'
                        onClick={createApiKey}
                      >
                        Create API Key
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card.Body>
            </Card>
          ) : (
            <Card className='w-100'>
              <Card.Body>
                <Card.Title>API Key</Card.Title>
                <Card.Body>
                  <Row className='mb-3'>
                    <Col xs={12}>
                      <p>{apiKey}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='d-flex justify-content-end'>
                      <Button
                        variant='danger'
                        className='me-3'
                        onClick={deleteApiKey}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card.Body>
            </Card>
          )}

          <h3 className='mt-5'>Testing API Key</h3>
          <p style={{ color: '#1E0342' }}>
            <b>Important Note:</b> Shortened links and QR codes generated using
            this testing API key will be removed within 24 hours.
          </p>
          {testApiKey === '' ? (
            <Card className='w-100'>
              <Card.Body>
                <Card.Title>API Key</Card.Title>
                <Card.Body>
                  <Row className='mb-3'>
                    <Col xs={12}>
                      <p>API key not generated yet</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='d-flex justify-content-end'>
                      <Button
                        variant='primary'
                        className='me-3'
                        onClick={createTestApiKey}
                      >
                        Create API Key
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card.Body>
            </Card>
          ) : (
            <Card className='w-100'>
              <Card.Body>
                <Card.Title>API Key</Card.Title>
                <Card.Body>
                  <Row className='mb-3'>
                    <Col xs={12}>
                      <p>{testApiKey}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='d-flex justify-content-end'>
                      <Button
                        variant='danger'
                        className='me-3'
                        onClick={deleteTestApiKey}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card.Body>
            </Card>
          )}
        </>
      )}

      <Modal show={oneTimeApiKey !== ''} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>One-Time API Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control type='text' disabled value={oneTimeApiKey} />
          <CopyToClipboard text={oneTimeApiKey} onCopy={handleCopy}>
            <div onClick={handleCopy}>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='tooltip'>
                    {isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
                  </Tooltip>
                }
              >
                <Button variant='outline'>
                  <MdContentCopy /> Copy to Clipboard
                </Button>
              </OverlayTrigger>
            </div>
          </CopyToClipboard>
          <p className='text-danger' style={{ fontWeight: 'bold' }}>
            Important: Please copy your API key now and store it securely. You
            won't be able to see it again after this. If you lose it, you'll
            need to generate a new one.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => setOneTimeApiKey('')}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorTextModal
        errorText={errorText}
        show={errorText.length > 0}
        onHide={() => setErrorText('')}
      />
    </>
  );
}
