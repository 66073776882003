import { useEffect, useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { BsFillThreadsFill } from 'react-icons/bs';
import { CiFacebook, CiLinkedin, CiInstagram } from 'react-icons/ci';
import {
  FaTiktok,
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaYoutube,
  FaTwitch,
} from 'react-icons/fa';
import { FaXTwitter, FaSquareXTwitter, FaThreads } from 'react-icons/fa6';
import { FiYoutube } from 'react-icons/fi';
import { IoMdGlobe } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { RiTiktokLine } from 'react-icons/ri';
import { FaLink } from 'react-icons/fa6';
import { ProfileBioLinkDeleteModal } from './ProfileBioLinkDeleteModal';

const Icons = [
  { name: 'CiFacebook', icon: CiFacebook },
  { name: 'CiLinkedin', icon: CiLinkedin },
  { name: 'CiInstagram', icon: CiInstagram },
  { name: 'FaTiktok', icon: FaTiktok },
  { name: 'FaFacebook', icon: FaFacebook },
  { name: 'FaInstagramSquare', icon: FaInstagramSquare },
  { name: 'FaLinkedin', icon: FaLinkedin },
  { name: 'FaXTwitter', icon: FaXTwitter },
  { name: 'FaSquareXTwitter', icon: FaSquareXTwitter },
  { name: 'FaThreads', icon: FaThreads },
  { name: 'BsFillThreadsFill', icon: BsFillThreadsFill },
  { name: 'RiTiktokLine', icon: RiTiktokLine },
  { name: 'IoMdGlobe', icon: IoMdGlobe },
  { name: 'FiYoutube', icon: FiYoutube },
  { name: 'FaYoutube', icon: FaYoutube },
  { name: 'FaTwitch', icon: FaTwitch },
];
export function LinkInBioProfileCard({
  link,
  bioUsername,
  deletedLink,
  setDeletedLink,
}: {
  link: any;
  bioUsername: string;
  deletedLink: boolean;
  setDeletedLink: any;
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);

  let icon: any = null;
  let customIcon: any = null;
  if (link.icon !== null && link.icon !== '') {
    icon = Icons.find((icon) => icon.name === link.icon)?.icon;
  } else if (
    link.customIcon !== null &&
    link.customIcon !== undefined &&
    link.customIcon !== ''
  ) {
    if (
      typeof link.customIcon == 'string' &&
      link.customIcon.startsWith('data:image')
    ) {
      customIcon = link.customIcon;
    } else if (
      link.customIcon instanceof Blob ||
      link.customIcon instanceof File
    ) {
      try {
        customIcon = URL.createObjectURL(link.customIcon);
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  return (
    <>
      <Card
        key={link.url}
        style={{ width: '18rem' }}
        className='mx-3 my-3 project-card'
      >
        <Card.Header style={{ backgroundColor: 'transparent' }}>
          <p>
            <FaLink size={32} className='mx-2' /> {link.title}
          </p>
        </Card.Header>

        <Card.Body>
          <Card.Text className='project-text'>
            <p style={{ fontWeight: 'bold' }}>Icon: </p>
            {customIcon ? (
              <Card.Img
                variant='top'
                src={customIcon}
                style={{ width: '32px', height: '32px' }}
              />
            ) : (
              icon && (
                <Card.Img
                  variant='top'
                  as={icon}
                  style={{ width: '32px', height: '32px' }}
                />
              )
            )}
            <p className='mt-2' style={{ fontWeight: 'bold' }}>
              Url:{' '}
            </p>
            {link.url}
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{ backgroundColor: 'transparent' }}>
          <Button
            variant='danger'
            className='w-100'
            onClick={() => {
              handleShowDeleteModal();
            }}
          >
            <MdDelete size={30} className='mx-1' />
            Delete
          </Button>
        </Card.Footer>
      </Card>

      <ProfileBioLinkDeleteModal
        handleShowDeleteModal={handleShowDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        showDeleteModal={showDeleteModal}
        bioLink={link}
        deletedLink={deletedLink}
        setDeletedLink={setDeletedLink}
        bioUsername={bioUsername}
        setSuccessText={setSuccessText}
        setErrorText={setErrorText}
      />
    </>
  );
}
