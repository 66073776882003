import { link } from 'fs';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { MdModeEditOutline, MdDelete } from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ProfileLinkInBioTab } from './ProfileLinkInBioTab';
import { useNavigate } from 'react-router';
import MemoizedTabs from './MemoizedTabs';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

interface LinkInBio {
  createdBy: string;
  title: string;
  bioImage: string;
  bioLinks: [
    {
      title: string;
      url: string;
      icon: string;
    }
  ];
  backgroundColor: string;
  shortId: string;
  createdAt: string;
  bioUsername: string;
  textColor: string;
}

export function LinkInBioProfile() {
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [linkInBio, setLinkInBio] = useState<LinkInBio[] | undefined>();
  const [linkInBioNotCreated, setLinkInBioNotCreated] = useState(false);
  const [deletedLink, setDeletedLink] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [bioUsername, setBioUsername] = useState('');
  const [bioTitle, setBioTitle] = useState('');
  const navigate = useNavigate();
  const [savedLinkInBio, setSavedLinkInBio] = useState(false);
  const [isAnother, setIsAnother] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  async function createLinkInBio() {
    setLoadingVisible(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/link-in-bio`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
          body: JSON.stringify({
            username: bioUsername,
            title: bioTitle,
            isAnother: isAnother,
          }),
        }
      );
      setLoadingVisible(false);
      if (!response.ok) {
        const result = await response.json();
        if (result.code && result.code > 700000) {
          setErrorText(`Failed to create link in bio :  ${result.message} `);
          setShowError(true);
        } else {
          setErrorText(
            `Failed to create link in bio -- Reference Number: ${requestId} -- ${result.message} `
          );
          setShowError(true);
        }
        setShow(false);
      } else {
        const result = await response.json();
        console.log('result', result);
        setLinkInBio(result);
        setShow(false);
        setErrorText('');
        setShowError(false);
        setDeletedLink(!deletedLink);
        setIsAnother(false);
        setLinkInBioNotCreated(false);
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  }
  const prev = useRef<any>([]);
  async function fetchLinkInBio() {
    const requestId = Date.now().toString();
    console.log(
      'fetching link in bio',
      JSON.stringify(linkInBio) !== JSON.stringify(prev.current)
    );

    setLoadingVisible(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/link-in-bio`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setLoadingVisible(false);
      if (response.status === 404) {
        setErrorText(`You Don't Have Link In Bio Yet`);
        setLinkInBioNotCreated(true);
        setErrorText('');
      } else if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      } else {
        const result = await response.json();
        setLinkInBio(result);
        if (JSON.stringify(linkInBio) !== JSON.stringify(prev.current)) {
          prev.current = result;
        }
        console.log('linkInBior', result);

        setErrorText('');
        setShowError(false);
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  }
  const prevDeletedLinkRef = useRef<boolean>();
  const prevSavedLinkInBioRef = useRef<boolean>();

  useEffect(() => {
    console.log('useEffect55');
    const prevDeletedLink = prevDeletedLinkRef.current;
    const prevSavedLinkInBio = prevSavedLinkInBioRef.current;

    console.log('deletedLink:', deletedLink);
    console.log('prevDeletedLink:', prevDeletedLink);
    console.log('savedLinkInBio:', savedLinkInBio);
    console.log('prevSavedLinkInBio:', prevSavedLinkInBio);
    console.log(
      'refetch',
      deletedLink !== prevDeletedLink && savedLinkInBio !== prevSavedLinkInBio
    );

    if (
      deletedLink !== prevDeletedLink &&
      savedLinkInBio !== prevSavedLinkInBio
    ) {
      // console.log('JSON.stringify(linkInBio)', JSON.stringify(linkInBio));
      // console.log('JSON.stringify(prev)', JSON.stringify(prev.current));

      fetchLinkInBio();
    }
    // if (JSON.stringify(linkInBio) !== JSON.stringify(prev.current)) {
    //   prev.current = linkInBio;
    //   // fetchLinkInBio();
    // }
  }, [deletedLink, savedLinkInBio, prev.current]);
  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <>
          {linkInBioNotCreated && (
            <>
              <Row className='d-flex align-items-center center justify-content-center py-3'>
                <Col className='d-flex justify-content-end'>
                  <p
                    className=' align-items-center center justify-content-center'
                    style={{ color: '#2C4E80' }}
                  >
                    You have not created your <b>awesome</b> LinkInBio yet,
                  </p>
                </Col>
                <Col className='d-flex justify-content-start align-items-center center'>
                  <Button variant='success' onClick={() => setShow(true)}>
                    Create Link In Bio
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {!linkInBioNotCreated && (
            <Button
              variant='success'
              className='w-25 mb-5'
              onClick={() => {
                setIsAnother(true);
                setShow(true);
              }}
            >
              Create Another Link In Bio
            </Button>
          )}
          <MemoizedTabs
            linkInBio={linkInBio}
            deletedLink={deletedLink}
            setDeletedLink={setDeletedLink}
            savedLinkInBio={savedLinkInBio}
            setSavedLinkInBio={setSavedLinkInBio}
            isAnother={isAnother}
          />

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Link In Bio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>
                  Bio Username
                </InputGroup.Text>
                <FormControl
                  placeholder='Ex: https://trimylink.io/CoolUsername'
                  aria-label='Bio Username'
                  aria-describedby='basic-addon1'
                  onChange={(e) => {
                    setBioUsername(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon2'>Bio Title</InputGroup.Text>
                <FormControl
                  placeholder='Bio Title'
                  aria-label='Bio Image'
                  aria-describedby='basic-addon2'
                  onChange={(e) => {
                    setBioTitle(e.target.value);
                  }}
                />
              </InputGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
              <Button variant='success' onClick={createLinkInBio}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
