import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

export function TopCountriesAnaltyics({
  errorText,
  setErrorText,
  from,
  to,
}: {
  errorText: string;
  setErrorText: any;
  from: any;
  to: any;
}) {
  const [countries, setCountries] = useState<any[]>([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  async function fetchCountries() {
    setLoadingVisible(true);
    const requestId = Date.now().toString() + ' - countries';
    let url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/countries`;
    if (from && to) {
      url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/countries?from=${from}&to=${to}`;
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        'request-id': requestId,
      },
    });
    setLoadingVisible(false);
    if (response.ok) {
      const jsonData = await response.json();
      setCountries(jsonData);
      setErrorText('');
      setShowError(false);
    } else {
      if (response.status === 400) {
        const error = (await response.json()).message;
        setErrorText(error);
        setShowError(true);
      } else if (response.status !== 403) {
        setErrorText(
          `Ops something went wrong at our end -- Reference Number: ${requestId}`
        );
        setShowError(true);
      }
    }
  }

  useEffect(() => {
    fetchCountries();
  }, [from, to]);

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Container
          className='mt-5 w-50'
          style={{ paddingLeft: '0', marginLeft: '0' }}
        >
          <h5 style={{ color: '#2A4978' }}>Statistics By Country</h5>
          {countries && Array.isArray(countries) && countries.length > 0 ? (
            countries.map((country) => (
              <Row className='mt-3'>
                <Col xs={10} sm={10} md={10} lg={10}>
                  <ProgressBar
                    now={country.percentage}
                    label={`${country.percentage}%`}
                    style={{ height: '20px' }}
                  />
                </Col>
                <Col>
                  <p>{country.countryName}</p>
                </Col>
              </Row>
            ))
          ) : (
            <p className='text-danger'>
              No countries data available, try change your <b>date range</b>{' '}
              filter
            </p>
          )}
        </Container>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
