import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import logo from './logo.svg';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import { TrimyLinkContext } from '../../../store/TrimyLinkContext';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

export function QRGenerateBox() {
  const trimyLinkCtx = useContext(TrimyLinkContext);
  const [inputValue, setInputValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [isToggleDisabled, setIsToggleDisabled] = useState(false);
  const [isEnterUrlDisabled, setIsEnterUrlDisabled] = useState(false);
  const [color, setColor] = useState('#000000');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [showAdvancedColorPlate, setShowAdvancedColorPlate] = useState(false);
  const [showBasicColorPlate, setShowBasicColorPlate] = useState(false);
  const [selectedQrCodeOption, setSelectedQrCodeOption] = useState(1);
  const [shortId, setShortId] = useState('');
  const [urlName, setUrlName] = useState('');
  const navigate = useNavigate();
  const [loadingVisible, setLoadingVisible] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      trimyLinkCtx.setGetStartedGenerateButtonText('Generate');
      localStorage.setItem('getStartedGenerateButtonText', 'Generate');
      setIsEnterUrlDisabled(false);
      const tier = localStorage.getItem('tier');
      if ('Standard' === tier || 'Premium' === tier) {
        setIsToggleDisabled(false);
      } else {
        setIsToggleDisabled(true);
      }

      if (tier && tier !== undefined) {
        if ('Free' === tier) {
          setShowBasicColorPlate(true);
          setShowAdvancedColorPlate(false);
        } else {
          setShowAdvancedColorPlate(true);
          setShowBasicColorPlate(false);
        }
      }
    } else {
      trimyLinkCtx.setGetStartedGenerateButtonText('Get Started For Free');
      setIsEnterUrlDisabled(true);
      localStorage.setItem(
        'getStartedGenerateButtonText',
        'Get Started For Free'
      );
      setIsToggleDisabled(true);
      setShowBasicColorPlate(false);
      setShowAdvancedColorPlate(false);
    }
  }, [trimyLinkCtx]);

  const requestId = Date.now().toString();
  const handleColorChangeForBackground = (event: any) => {
    setBackgroundColor(event.target.value);
  };
  const handleColorChange = (event: any) => {
    setColor(event.target.value);
  };
  async function downloadQRCode(format: string) {
    const requestId = Date.now().toString();
    const response = await fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/qr/download/${shortId}?format=${format}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
          authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
        },
        redirect: 'follow',
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        const error = (await response.json()).message;
        setErrorText(error);
        setShowError(true);
      } else {
        setErrorText(
          `Ops something went wrong at our end -- Reference Number: ${requestId}`
        );
        setShowError(true);
      }
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `file${format}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  async function generateQR() {
    setLoadingVisible(true);
    if (trimyLinkCtx.getStartedGenerateButtonText === 'Get Started For Free') {
      navigate('/signup');
      return;
    }
    try {
      let requestBody;
      if (inputValue !== undefined && inputValue !== '') {
        requestBody = JSON.stringify({
          url: inputValue,
          option: selectedQrCodeOption || undefined,
          bgColor: backgroundColor,
          qrColor: color,
          name: urlName || inputValue,
        });

        const response = await fetch(
          `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/qr/shorten`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
              'request-id': requestId,
            },
            body: requestBody,
          }
        );
        const result = await response.json();

        setLoadingVisible(false);
        if (!response.ok) {
          if (response.status === 400) {
            const error = result.message;
            setErrorText(error);
            setShowError(true);
          } else {
            setErrorText(
              `Ops something went wrong at our end -- Reference Number: ${requestId}`
            );
            setShowError(true);
          }
          setSuccessText('');
        } else {
          setErrorText('');
          setShowError(false);
          setInputValue('');
          setUrlName('');
          setSuccessText(result.image);
          setShortId(result.shortId.shortid);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  }

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Container fluid className='y-flex h-100'>
          <Col
            xs={12}
            md={12}
            className='align-items-center center justify-content-center custom-width'
          >
            <InputGroup className='mb-3 align-items-center center justify-content-center'>
              <div className='w-100'>
                <Row>
                  <Form.Control
                    placeholder='URL Name /  Ex: My Company Website'
                    aria-label='urlname'
                    value={urlName}
                    disabled={isEnterUrlDisabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUrlName(e.target.value);
                    }}
                  />
                </Row>
                <Row>
                  <Form.Control
                    className='mt-3'
                    placeholder='Enter Your long URL here '
                    aria-label='Username'
                    value={inputValue}
                    disabled={isEnterUrlDisabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValue(e.target.value);
                    }}
                  />
                </Row>
              </div>
            </InputGroup>

            <Row className='justify-content-center'>
              <Col xs={12} md={12} sm={12}>
                <Button
                  variant='success'
                  className='w-100'
                  onClick={generateQR}
                >
                  {trimyLinkCtx.getStartedGenerateButtonText}
                </Button>
              </Col>
            </Row>
            {successText && (
              <Row className='justify-content-center'>
                <div className='d-flex justify-content-center align-items-center'>
                  <img className='my-3' src={successText} alt='QR Code' />
                </div>
              </Row>
            )}
          </Col>

          <Row>
            <div className='d-flex justify-content-center'>
              <Dropdown className='mb-3 mt-3 sm-w-50 xs-w-25 md-w-25'>
                <Dropdown.Toggle
                  variant='success'
                  id='dropdown-basic'
                  className='w-100'
                  disabled={isEnterUrlDisabled}
                >
                  Download
                </Dropdown.Toggle>
                <Row className='justify-content-center'></Row>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => downloadQRCode('PNG')}>
                    PNG
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => downloadQRCode('JPEG')}>
                    JPEG
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => downloadQRCode('SVG')}>
                    SVG (Black & White)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Row>

          {showAdvancedColorPlate && (
            <Container className='d-flex justify-content-center'>
              <Row className='mt-5 align-items-center justify-content-center'>
                <Col xs={5}>
                  <Form.Group
                    controlId='formColorPicker'
                    className='d-inline-block'
                  >
                    <Form.Label>QR Code Draw colors</Form.Label>
                    <Form.Control
                      type='color'
                      value={color}
                      onChange={handleColorChange}
                    />
                  </Form.Group>
                </Col>

                <Col xs={5}>
                  <Form.Group
                    controlId='formColorPicker'
                    className='d-inline-block'
                  >
                    <Form.Label>QR Code Background color</Form.Label>
                    <Form.Control
                      type='color'
                      value={backgroundColor}
                      onChange={handleColorChangeForBackground}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <QRCode
                    value='http://example.com'
                    fgColor={color}
                    bgColor={backgroundColor}
                  />
                </Col>
              </Row>
            </Container>
          )}

          {showBasicColorPlate && (
            <Container className='d-flex justify-content-center'>
              <Row className='align-items-center justify-content-center'>
                <Col xs={4} className='text-center'>
                  <h5>Option 1</h5>
                  <QRCode
                    value='http://example.com'
                    fgColor='#000000'
                    bgColor='#ffffff'
                  />
                  <Form.Check
                    type='radio'
                    name='qrOption'
                    id='option1'
                    defaultChecked
                    label='Select this QR code'
                    onChange={() => setSelectedQrCodeOption(1)}
                  />
                </Col>
                <Col xs={4} className='text-center'>
                  <h5>Option 2</h5>
                  <QRCode
                    value='http://example.com'
                    fgColor='#007F73'
                    bgColor='#ffffff'
                  />
                  <Form.Check
                    type='radio'
                    name='qrOption'
                    id='option2'
                    label='Select this QR code'
                    onChange={() => setSelectedQrCodeOption(2)}
                  />
                </Col>
                <Col xs={4} className='text-center'>
                  <h5>Option 3</h5>
                  <QRCode
                    value='http://example.com'
                    fgColor='#7F27FF'
                    bgColor='#ffffff'
                  />
                  <Form.Check
                    type='radio'
                    name='qrOption'
                    id='option3'
                    label='Select this QR code'
                    onChange={() => setSelectedQrCodeOption(3)}
                  />
                </Col>
              </Row>
            </Container>
          )}
          <ErrorTextModal
            errorText={errorText}
            show={showError}
            onHide={() => setShowError(false)}
          />
        </Container>
      )}
    </>
  );
}
