import React, { memo, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { ProfileLinkInBioTab } from './ProfileLinkInBioTab';

const MemoizedTabs = memo(
  ({
    linkInBio,
    deletedLink,
    setDeletedLink,
    savedLinkInBio,
    setSavedLinkInBio,
    isAnother,
  }: {
    linkInBio: any;
    deletedLink: any;
    setDeletedLink: any;
    savedLinkInBio: any;
    setSavedLinkInBio: any;
    isAnother: any;
  }) => {
    useEffect(() => {
      console.log('memoTab', linkInBio);
    }, [linkInBio]);

    return (
      <Tabs
        defaultActiveKey='profile-0'
        transition={false}
        id='noanim-tab-example'
        className='mb-5 align-items-center justify-content-center center'
      >
        {linkInBio &&
          Array.isArray(linkInBio) &&
          linkInBio.length > 0 &&
          linkInBio.map((bio: any, index: number) => {
            console.log('rendeing tabs', bio);
            return (
              <Tab eventKey={`profile-${index}`} title={bio.bioUsername}>
                <ProfileLinkInBioTab
                  linkInBio={bio}
                  deletedLink={deletedLink}
                  setDeletedLink={setDeletedLink}
                  savedLinkInBio={savedLinkInBio}
                  setSavedLinkInBio={setSavedLinkInBio}
                  isAnother={isAnother}
                />
              </Tab>
            );
          })}
      </Tabs>
    );
  }
);

export default MemoizedTabs;
