import {
  faEnvelope,
  faKey,
  faUser,
  faCalendar,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import countries from 'i18n-iso-countries';
import english from '../../countries.json';
import { Footer } from '../home-page/Footer';
import Header from '../home-page/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';
import { GoogleLoginComponent } from './GoogleLoginComponent';
import refreshToken from './RefreshToken';
import { Loading } from '../Loading';
import { set } from 'date-fns';
import { ErrorTextModal } from '../popup/ErrorTextModal';
import { SuccessTextModal } from '../popup/SuccessTextModal';

countries.registerLocale(english);
const countryList = Object.entries(countries.getNames('en')).map(
  ([value, label]) => ({ label, value })
);

export function Signup() {
  const trimyLinkCtx = useContext(TrimyLinkContext);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const location = useLocation();
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [country, setCountry] = useState<any>();
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const [selectedTier, setSelectedTier] = useState<any>();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [selectedTierName, setSelectedTierName] = useState<string>();
  const packages = [
    {
      name: 'Free',
      price: '$0/month',
      features: [
        'Up to 15 shortened URLs per month',
        'Up to 2 QR codes per month',
      ],
    },
    {
      name: 'Basic',
      price: '$6/month',
      features: [
        'Up to 150 shortened URLs per month',
        'Up to 8 QR codes per month',
      ],
    },
    {
      name: 'Standard',
      price: '$18/month',
      features: [
        'Up to 500 shortened URLs per month',
        'Up to 20 QR codes per month',
      ],
    },
    {
      name: 'Premium',
      price: '$30/month',
      features: [
        'Up to 1000 shortened URLs per month',
        'Up to 35 QR codes per month',
      ],
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('loginLogOutButtonText')) {
      if (localStorage.getItem('loginLogOutButtonText') === 'Logout') {
        navigate('/');
      }
    }
    const state = location.state;
    if (state && (!selectedTierName || selectedTierName.length === 0)) {
      setSelectedTierName(state.plan.name);
      setSelectedTier(state.plan);
    }
    if (!state && (!selectedTierName || selectedTierName.length === 0)) {
      setSelectedTierName('Free');
    }
  }, [trimyLinkCtx, selectedTier, selectedTierName]);

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Container className=' y-flex justify-content-center mb-5'>
          <Header />

          <Row>
            <Col sm={8} md={8} className=' '>
              <h3>Create your account</h3>

              <Row>
                <Col xs={12} md={9}>
                  <Form className='y-flex center align-items-center justify-content-center'>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label className='mt-2'>Email address</Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                        <Form.Control
                          type='email'
                          className='input-group-addon glyphicon glyphicon-user'
                          placeholder='Enter email'
                          value={inputEmail}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setInputEmail(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label className='mt-2'>Username</Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        <Form.Control
                          type='email'
                          className='input-group-addon glyphicon glyphicon-user'
                          placeholder='Username'
                          value={username}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setUsername(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label className='mt-2'>Password</Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faKey} />
                        </span>
                        <Form.Control
                          type='password'
                          placeholder='Password'
                          value={inputPassword}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setInputPassword(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label className='mt-2'>
                        {' '}
                        Confirm Password
                      </Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faKey} />
                        </span>
                        <Form.Control
                          type='password'
                          placeholder='Confirm Password'
                          value={confirmPassword}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label className='mt-2'>Fulll Name</Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        <Form.Control
                          type='text'
                          placeholder='Full Name'
                          value={fullName}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFullName(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label className='mt-2'>Date of birth</Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>
                        <Form.Control
                          type='date'
                          placeholder='Date of birth'
                          value={dateOfBirth}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setDateOfBirth(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label className='mt-2'>Country</Form.Label>

                      <Select
                        options={countryList}
                        className='w-100'
                        onChange={setCountry}
                      />
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label className='mt-2'>Mobile Number</Form.Label>
                      <div className='input-group'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={faMobile} />
                        </span>
                        <Form.Control
                          type='text'
                          placeholder='Mobile Number - ex: +9711234567890'
                          value={phoneNumber}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setPhoneNumber(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId='formBasicCheckbox' className='mt-4'>
                      <Form.Check
                        type='checkbox'
                        checked={acceptedTerms}
                        label={
                          <span>
                            I agree to the{' '}
                            <Link
                              to='/terms-and-conditions'
                              target='_blank'
                              style={{
                                color: '#00215E',
                                textDecoration: 'underline',
                              }}
                            >
                              <b>terms and conditions</b>
                            </Link>
                          </span>
                        }
                        onChange={() => setAcceptedTerms(!acceptedTerms)}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Col>

            <Col sm={4} md={4} className='  px-3'>
              <Row>
                <SuccessTextModal
                  successText={successText}
                  show={successText.length > 0}
                  onHide={() => setSuccessText('')}
                />

                <Card className='text-center position-relative d-flex flex-column mt-5'>
                  <Card.Header className='pt-4 bg-transparent'>
                    <Dropdown>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        {selectedTierName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTierName('Free');
                            setSelectedTier(
                              packages.find((p) => p.name === 'Free')
                            );
                          }}
                          active={
                            selectedTierName !== undefined &&
                            selectedTierName.toUpperCase() === 'FREE'
                          }
                        >
                          Free
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTierName('Basic');
                            setSelectedTier(
                              packages.find((p) => p.name === 'Basic')
                            );
                          }}
                          active={
                            selectedTierName !== undefined &&
                            selectedTierName.toUpperCase() === 'BASIC'
                          }
                        >
                          Basic
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTierName('Standard');
                            setSelectedTier(
                              packages.find((p) => p.name === 'Standard')
                            );
                          }}
                          active={
                            selectedTierName !== undefined &&
                            selectedTierName.toUpperCase() === 'STANDARD'
                          }
                        >
                          Standard
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTierName('Premium');
                            setSelectedTier(
                              packages.find((p) => p.name === 'Premium')
                            );
                          }}
                          active={
                            selectedTierName !== undefined &&
                            selectedTierName.toUpperCase() === 'PREMIUM'
                          }
                        >
                          Premium
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Card.Header>
                  <p className='mt-3'>
                    <b>
                      {selectedTier?.price ||
                        packages.find((p) => p.name === selectedTierName)
                          ?.price}
                    </b>
                  </p>

                  <Card.Body className='flex-grow-1'>
                    <Card.Text>
                      <ul>
                        {(
                          selectedTier?.features ||
                          packages.find((p) => p.name === selectedTierName)
                            ?.features ||
                          []
                        ).map((feature: any) => (
                          <li className='mt-2 small' key={feature}>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Button
                  variant='primary'
                  type='submit'
                  className=' w-100 mt-4'
                  onClick={handleSignUp}
                >
                  Signup
                </Button>
              </Row>
              <Row>
                <p className='d-flex justify-content-center align-items-center mt-5'>
                  <h5>Already have an account?</h5>
                  <Button variant='link' onClick={() => navigate('/')}>
                    Login
                  </Button>
                </p>
              </Row>
              <Row className='mx-3 px-3'>
                <GoogleLoginComponent
                  googleLoginComponentProps={{
                    setErrorText: setErrorText,
                  }}
                />
              </Row>
            </Col>
          </Row>

          <Row>
            <Footer />
          </Row>

          <ErrorTextModal
            errorText={errorText}
            show={showError}
            onHide={() => setShowError(false)}
          />
        </Container>
      )}
    </>
  );

  async function doPayment(tierName: string) {
    console.log('going to checkout', tierName, inputEmail);
    navigate('/checkout', { state: { tierName: tierName, email: inputEmail } });
  }
  async function handleSignUp(event: any) {
    event.preventDefault();
    setLoadingVisible(true);
    console.log('selected Tier');
    const requestPayload = {
      email: inputEmail,
      password: inputPassword,
      confirmPassword: confirmPassword,
      name: fullName,
      mobileNumber: phoneNumber,
      country: country?.label,
      dateOfBirth: dateOfBirth,
      username: username,
      tierName: selectedTier ? selectedTier.name : 'Free',
      acceptedTermsAndConditions: acceptedTerms,
    };
    const requestId = Date.now().toString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/users/signup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          referrerPolicy: 'strict-origin-when-cross-origin',
          credentials: 'include',
          body: JSON.stringify(requestPayload),
        }
      );
      setLoadingVisible(false);
      if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        }
      } else {
        const data = await response.json();
        setErrorText('');
        setShowError(false);
        localStorage.setItem('jwtToken', data.jwtToken);
        localStorage.setItem('tier', data.tier);
        localStorage.setItem('refreshToken', data.refreshToken);

        const expiresInMilliseconds = data.expiresIn * 1000;
        localStorage.setItem(
          'expiresAt',
          (Date.now() + expiresInMilliseconds).toString()
        );
        setTimeout(refreshToken, expiresInMilliseconds - 5000);

        if (selectedTier) {
          const tierName = selectedTier.name;
          await doPayment(tierName);
        } else {
          navigate('/');
        }
      }
    } catch (error) {
      console.log(error);
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  }
}
