import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import logo from './../../logo.svg';
import './../../../App.css';
import { BsFillThreadsFill } from 'react-icons/bs';
import { CiFacebook, CiLinkedin, CiInstagram } from 'react-icons/ci';
import {
  FaTiktok,
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaYoutube,
  FaTwitch,
} from 'react-icons/fa';
import { FaXTwitter, FaSquareXTwitter, FaThreads } from 'react-icons/fa6';
import { FiYoutube } from 'react-icons/fi';
import { IoMdGlobe } from 'react-icons/io';
import { RiTiktokLine } from 'react-icons/ri';
import { useEffect } from 'react';
const Icons = [
  { name: 'CiFacebook', icon: CiFacebook },
  { name: 'CiLinkedin', icon: CiLinkedin },
  { name: 'CiInstagram', icon: CiInstagram },
  { name: 'FaTiktok', icon: FaTiktok },
  { name: 'FaFacebook', icon: FaFacebook },
  { name: 'FaInstagramSquare', icon: FaInstagramSquare },
  { name: 'FaLinkedin', icon: FaLinkedin },
  { name: 'FaXTwitter', icon: FaXTwitter },
  { name: 'FaSquareXTwitter', icon: FaSquareXTwitter },
  { name: 'FaThreads', icon: FaThreads },
  { name: 'BsFillThreadsFill', icon: BsFillThreadsFill },
  { name: 'RiTiktokLine', icon: RiTiktokLine },
  { name: 'IoMdGlobe', icon: IoMdGlobe },
  { name: 'FiYoutube', icon: FiYoutube },
  { name: 'FaYoutube', icon: FaYoutube },
  { name: 'FaTwitch', icon: FaTwitch },
];

export function LinkInBio() {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state) navigate(`/${window.location.pathname.split('/')[2]}`);
  }, []);

  return (
    <>
      {state && (
        <Container
          style={{
            background: state.data.color2
              ? `linear-gradient(to right, ${state.data.color1}, ${state.data.color2})`
              : state.data.color1,
            maxWidth: '100vw',
            minHeight: '100vh',
            padding: '50px',
          }}
        >
          <div>
            <Row className='d-flex align-items-center justify-content-center w-100'>
              {state.data.bioImage && (
                <Image
                  id='bioImage'
                  src={state.data.bioImage}
                  style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: 'transparent',
                  }}
                  roundedCircle
                  className='mt-5'
                />
              )}
            </Row>
            <Row>
              <div className='d-flex align-items-center justify-content-center w-100  mt-3'>
                <Row style={{ color: state.data.textColor }}>
                  <h4>{state.data.title}</h4>
                </Row>
              </div>
            </Row>

            {state &&
            state.data.bioLinks &&
            Array.isArray(state.data.bioLinks) ? (
              <div>
                {state.data.bioLinks.map((link: any) => {
                  let icon: any = null;
                  let customIcon: any = null;
                  if (link.icon !== null && link.icon !== '') {
                    icon = Icons.find((icon) => icon.name === link.icon)?.icon;
                  }
                  if (link.customIcon !== null) {
                    customIcon = link.customIcon;
                  }
                  return (
                    <Card
                      className='d-flex flex-row align-items-center justify-content-center center w-100 mt-5 card-hover'
                      style={{ width: '18rem', margin: '10px' }}
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/${link.shortId}`,
                          '_blank'
                        )
                      }
                    >
                      <Col xs={2}>
                        {customIcon ? (
                          <Card.Img
                            variant='top'
                            className='mx-3'
                            src={customIcon}
                            style={{ width: '32px', height: '32px' }}
                          />
                        ) : (
                          icon && (
                            <Card.Img
                              variant='top'
                              as={icon}
                              className='mx-3'
                              style={{ width: '32px', height: '32px' }}
                            />
                          )
                        )}
                      </Col>
                      <Card.Body className='d-flex flex-row align-items-center'>
                        <Col xs={6}>
                          <Card.Text
                            className={`mr-3 link-in-bio-url-name `}
                            style={{ color: state.data.textColor }}
                          >
                            {link.title}
                          </Card.Text>
                        </Col>
                      </Card.Body>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <h3>No links found.</h3>
            )}
          </div>

          <Row className='mb-0 text-center mt-5'>
            <p>
              Powered by{' '}
              <a
                href='https://trimylink.io'
                target='_blank'
                rel='noopener noreferrer'
              >
                <b>TrimyLink</b>
              </a>
            </p>
          </Row>
        </Container>
      )}
    </>
  );
}
