import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import Header from './home-page/Header';
import { Footer } from './home-page/Footer';

export function BlogDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [blog, setBlog] = useState<any>();
  useEffect(() => {
    if (!state || !state.blog) navigate('/blogs');
    setBlog(state?.blog);
  }, []);

  return (
    <Container>
      <Row>
        <Header />
      </Row>
      <Row>
        <div style={{ height: '50vh', overflow: 'hidden' }} className='mt-3'>
          <img
            src={blog?.image}
            alt={blog?.title}
            className='img-fluid w-100 h-100 mt-5'
            style={{ objectFit: 'cover' }}
          />
        </div>
      </Row>
      <Row className='mt-5'>
        <div dangerouslySetInnerHTML={{ __html: blog?.description }} />
      </Row>
      <Row>
        <Footer />
      </Row>
    </Container>
  );
}
