import { Container, Row } from 'react-bootstrap';
import Header from './home-page/Header';
import { Footer } from './home-page/Footer';

export function AboutUs() {
  return (
    <Container>
      <Row>
        <Header />
      </Row>

      <Row className='mt-5'>
        <h3>About Us</h3>
        <p>
          Welcome to trimylink.io, your ultimate destination for streamlining
          your online presence and maximizing your digital impact. Founded with
          a passion for innovation and a commitment to excellence, trimylink.io
          is dedicated to empowering individuals and businesses alike with the
          tools they need to succeed in today's fast-paced digital landscape.
        </p>
      </Row>

      <Row className='mt-5'>
        <h3>Our Mission</h3>
        <p>
          At trimylink.io, our mission is simple: to help you make every click
          count. We understand that in the digital age, first impressions are
          everything, and that's why we're dedicated to helping you make a
          lasting impact and stand out from the crowd. Whether you're a social
          media influencer, a small business owner, or a marketing professional,
          we believe that everyone deserves access to powerful, intuitive tools
          that simplify their online journey and drive tangible results.
        </p>
      </Row>

      <Row className='mt-5'>
        <h3>Our Services</h3>
        <p>
          At trimylink.io, we offer a range of services designed to optimize
          your online presence and amplify your digital impact. From our Shorten
          URL service, which transforms lengthy URLs into sleek, shareable
          links, to our QR Code Generator, which bridges the gap between the
          physical and digital worlds, to our Link in Bio solution, which
          consolidates your social media links into a single, cohesive landing
          page – we have everything you need to elevate your online presence and
          achieve your goals.
        </p>
      </Row>

      <Row className='mt-5'>
        <h3>Why Choose Us</h3>
        <p>
          So why choose trimylink.io? It's simple – because we're more than just
          a URL shortening service or a QR code generator or a link in bio
          solution. We're a partner in your digital journey, committed to
          providing you with the tools, resources, and support you need to
          succeed. With our intuitive platform, customizable solutions, and
          dedicated customer service team, you can trust trimylink.io to be
          there every step of the way, helping you navigate the complexities of
          the digital landscape and achieve your vision.
        </p>
      </Row>

      <Row className='mt-5'>
        <h3>Join the trimylink.io Revolution</h3>
        <p>
          In conclusion, trimylink.io isn't just a service – it's a revolution.
          A revolution in streamlining your online presence. A revolution in
          maximizing your digital impact. A revolution in making every click
          count. So join us today and experience the power of trimylink.io for
          yourself. Together, we'll revolutionize the way you navigate the
          digital world and unlock your full potential online.
        </p>
      </Row>

      <Row>
        <Footer />
      </Row>
    </Container>
  );
}
