import { Button, Modal } from 'react-bootstrap';
import { TiTick } from 'react-icons/ti';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';

export function SuccessTextModal({
  show,
  onHide,
  successText,
}: {
  show: boolean;
  onHide: () => void;
  successText: string;
}) {
  return (
    <>
      <Modal show={show} onHide={onHide} className='w-100'>
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <div></div>
          <Modal.Title className='text-center justify-content-center align-items-center center'>
            <TiTick color='green' size={32} />
          </Modal.Title>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {successText}
            <CopyToClipboard text={successText}>
              <MdContentCopy />
            </CopyToClipboard>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-success' onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
