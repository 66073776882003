import { useEffect, useState } from 'react';
import { Modal, Dropdown, Button, Form } from 'react-bootstrap';
import { ErrorTextModal } from '../popup/ErrorTextModal';

export function ProfileLinkDeleteModal({
  handleShowDeleteModal,
  handleCloseDeleteModal,
  showDeleteModal,
  project,
  setSuccessText,
  setErrorText,
  handleDeleteProject,
}: {
  handleShowDeleteModal: any;
  handleCloseDeleteModal: any;
  showDeleteModal: any;
  project: any;
  setSuccessText: any;
  setErrorText: any;
  handleDeleteProject: any;
}) {
  useEffect(() => {}, []);
  async function deleteProject() {
    const requestId = Date.now().toString();
    const response = await fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/common/delete-url`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
        body: JSON.stringify({
          shortId: project.shortid,
        }),
      }
    );
    if (!response.ok) {
      if (response.status === 400) {
        const error = (await response.json()).message;
        setErrorText(error);
      } else {
        setErrorText(
          `Ops something went wrong at our end -- Reference Number: ${requestId}`
        );
      }
      setSuccessText('');
    } else {
      setErrorText('');
      setSuccessText('Project Deleted successfully');
      handleDeleteProject(project.shortid);
    }

    handleCloseDeleteModal();
  }

  return (
    <>
      {project && (
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Deleting Shorten Url Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='formBasicOrginalUrl'>
              <Form.Label className='mt-2'>
                <p className='project-text'>
                  {' '}
                  Are you sure you want to delete <b>{project.name}</b>
                </p>
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='py-3'>
            <Button variant='secondary' onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant='danger' onClick={deleteProject}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
