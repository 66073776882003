import { useEffect, useState } from 'react';
import { Modal, Dropdown, Button, Form } from 'react-bootstrap';
import { ErrorTextModal } from '../../popup/ErrorTextModal';
import { de } from 'date-fns/locale';

export function ProfileBioLinkDeleteModal({
  handleShowDeleteModal,
  handleCloseDeleteModal,
  showDeleteModal,
  bioLink,
  deletedLink,
  setDeletedLink,
  bioUsername,
  setSuccessText,
  setErrorText,
}: {
  handleShowDeleteModal: any;
  handleCloseDeleteModal: any;
  showDeleteModal: any;
  bioLink: any;
  deletedLink: boolean;
  setDeletedLink: any;
  bioUsername: any;
  setSuccessText: any;
  setErrorText: any;
}) {
  useEffect(() => {
    console.log('bioLink:', bioLink);
    console.log('bioUsername:', bioUsername);
  }, []);
  async function deleteBioLink() {
    const requestId = Date.now().toString();
    console.log('bioLink123:', bioLink);
    if (bioLink) {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/link-in-bio/${bioUsername}/${bioLink.shortId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
          body: JSON.stringify({
            shortId: bioLink.shortid,
          }),
        }
      );
      if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
        }
        setSuccessText('');
      } else {
        setDeletedLink(!deletedLink);
      }
    } else {
      console.log('deleted!');
      setDeletedLink(!deletedLink);
    }
    handleCloseDeleteModal();
  }

  return (
    <>
      {bioLink && (
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Deleting Bio Link Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='formBasicOrginalUrl'>
              <Form.Label className='mt-2'>
                <p className='project-text'>
                  {' '}
                  Are you sure you want to delete <b>{bioLink.title}</b>
                </p>
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='py-3'>
            <Button variant='secondary' onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant='danger' onClick={deleteBioLink}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
