import React, { forwardRef, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './../../App.css';
// export function Hero() {
const Hero = forwardRef((props, ref: any) => {
  const [heroButtonText, setHeroButtonText] = useState('Get Started For Free');

  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      setHeroButtonText('Generate');
    } else {
      setHeroButtonText('Get Started For Free');
    }
  });

  return (
    <div className='hero-section d-flex justify-content-center' ref={ref}>
      <Row className=' d-flex align-items-center center justify-content-center'>
        <Col>
          <h6>
            The Ultimate Solution for Shortening URLs, Generating QR Codes, and
            Simplifying Link Management
          </h6>
          <p>
            Welcome to TrimyLink, your go-to platform for all things
            URL-related! Whether you're a marketer, business owner, or social
            media enthusiast, TrimyLink has everything you need to streamline
            your online presence. With our user-friendly tools, you can
          </p>
          <ul>
            <li className='mt-3'>
              Shorten Long URLs: Say goodbye to unwieldy links. Our URL
              shortening feature makes sharing links easier than ever.
            </li>
            <li className='mt-3'>
              Generate Custom QR Codes: Enhance your marketing efforts with
              unique QR codes that drive engagement and trackable insights.
            </li>
            <li className='mt-3'>
              Simplify Link Management: Keep your links organized and accessible
              with our intuitive link management system.
            </li>
          </ul>
        </Col>
        <Col md={3} xs={12}>
          <img
            src={`${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/images/Hero1.svg`}
            alt='why to choose trimy link'
            className='img-fluid d-none d-md-block'
          />
        </Col>
      </Row>
    </div>
  );
});
export default Hero;
