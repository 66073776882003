import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import { stat } from 'fs';

export function Return() {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('return page');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const success = urlParams.get('success');
    const requestId = Date.now().toString(); //TODO replace with sessionId
    fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/checkout/session-status?session_id=${sessionId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'request-id': sessionId || requestId,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log('data: ', data);
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return <Navigate to='/checkout' />;
  }

  if (!status || status === null) navigate('/');

  if (status === 'complete') {
    return (
      <section id='success'>
        <Modal show={showModal} onHide={() => navigate('/')}>
          <Modal.Header closeButton>
            <Modal.Title>Payment Completed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            We appreciate your business! A confirmation email will be sent to{' '}
            {customerEmail}. If you have any questions, please email{' '}
            <a href='mailto:support@trimylink.io'>
              mailto:support@trimylink.io
            </a>
            .
            <p>
              <br />
              <b>Note:</b> Please logout and relogin for new tier take effect.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='success' onClick={() => navigate('/')}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    );
  }

  return null;
}
