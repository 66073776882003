import React, { RefObject, forwardRef, useState } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { URLGenerateBox } from '../products/shorten-url/URLGenerateBox';
import './../../App.css';
import { QRGenerateBox } from '../products/shorten-qr/QRGenerateBox';
import { Col, Row } from 'react-bootstrap';
// const GenerateBox = forwardRef((props, ref: any) => {
export function GenerateBox() {
  const [key, setKey] = useState('url');

  return (
    <>
      <div className='d-flex justify-content-center'>
        <div className='d-inline-block'>
          <Tabs
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k) => k && setKey(k)}
            className='mb-3'
          >
            <Tab eventKey='url' title='URL'>
              <URLGenerateBox />
            </Tab>
            <Tab eventKey='qr' title='QR'>
              <QRGenerateBox />
            </Tab>
          </Tabs>
          <Row className='d-flex justify-content-center mt-3 align-items-center'>
            <Col xs={12} md={8} className='px-4'>
              No credit card required. Your free plan includes:
            </Col>
          </Row>
        </div>
      </div>
      <Row className='justify-content-center mt-3 align-items-center gx-2'>
        <Col
          xs={12}
          md={4}
          className='d-flex justify-content-center align-items-center'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-check-circle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
          </svg>
          <p style={{ marginLeft: '0.5rem' }} className='mb-0'>
            Short URL
          </p>
        </Col>
        <Col
          xs={12}
          md={4}
          className='d-flex justify-content-center align-items-center'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-check-circle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
          </svg>
          <p style={{ marginLeft: '0.5rem' }} className='mb-0'>
            QR Codes
          </p>
        </Col>
        <Col
          xs={12}
          md={4}
          className='d-flex justify-content-center align-items-center'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-check-circle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
          </svg>
          <p style={{ marginLeft: '0.5rem' }} className='mb-0'>
            Link in Bio
          </p>
        </Col>
      </Row>
    </>
  );
}
// export ` GenerateBox;
