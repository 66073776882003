import React from 'react';
import '../../App.css';
import logo from './../logo.svg';
import Image from 'react-bootstrap/Image';
import { Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router';

export function Footer() {
  const navigate = useNavigate();
  const navigateTo = (url: string) => {
    navigate(url);
  };

  return (
    <footer className='page-footer font-small blue pt-4 mt-5'>
      <hr />
      <div className='container-fluid text-center text-md-left '>
        <div className='d-flex justify-center align-items-center  center'>
          <Row className='w-100'>
            <Col xs={12} sm={6} md={3}>
              <div className='mt-md-0 mx-3'>
                <Image
                  src={logo}
                  rounded
                  className='img-fluid '
                  style={{ maxWidth: '175px', minWidth: '175px' }}
                />
                <p className='mt-2'>
                  Connecting Your World, One Link at a Time
                </p>
                <div className='mt-2 '>
                  <a
                    className='px-3'
                    href='https://www.facebook.com/profile.php?id=61559636137402'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaFacebook size={32} color='gray' />
                  </a>
                  <a
                    className='px-3'
                    href='https://www.twitter.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaTwitter size={32} color='gray' />
                  </a>
                  <a
                    className='px-3'
                    href='https://instagram.com/trimylinkio'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaInstagram size={32} color='gray' />
                  </a>
                  <a
                    className='px-3'
                    href='https://www.linkedin.com/company/103177084/admin/feed/posts/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaLinkedin size={32} color='gray' />
                  </a>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={2}>
              <div className='mb-md-0 mb-3'>
                <h5 className='text-uppercase'>Company</h5>
                <ul className='list-unstyled'>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      target='_blank'
                      rel='noopener noreferrer'
                      href='/terms-and-conditions'
                    >
                      Terms & Conditions
                    </a>
                  </li>

                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      target='_blank'
                      rel='noopener noreferrer'
                      href='/privacy-policy'
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      target='_blank'
                      rel='noopener noreferrer'
                      href='/cookie-policy'
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigateTo('/pricing')}
                    >
                      Pricing
                    </a>
                  </li>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigateTo('/about-us')}
                    >
                      About us
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} sm={6} md={2}>
              <div className='mb-md-0 mb-3'>
                <h5 className='text-uppercase'>Products</h5>
                <ul className='list-unstyled'>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigate('/URLShortenPage')}
                    >
                      URL Shortening
                    </a>
                  </li>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigate('/QRShortenPage')}
                    >
                      QR Codes
                    </a>
                  </li>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigate('/LinkInBioShortenPage')}
                    >
                      Ling In Bio
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} sm={6} md={2}>
              <div className='mb-md-0 mb-3'>
                <h5 className='text-uppercase'>Resources</h5>
                <ul className='list-unstyled'>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() =>
                        window.open(
                          '/api-docs.html',
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      API Documentation
                    </a>
                  </li>
                  <li className='footer-link-container'>
                    <OverlayTrigger
                      overlay={<Tooltip id='tooltip'>Coming soon</Tooltip>}
                    >
                      <a className='footer-link'>Mobile App</a>
                    </OverlayTrigger>
                  </li>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigate('/blogs')}
                    >
                      Blog
                    </a>
                  </li>
                  <li className='footer-link-container'>
                    <a
                      className='footer-link'
                      onClick={() => navigate('/contact-us')}
                    >
                      Conact us
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className='footer-copyright text-center py-3'>
        © {new Date().getFullYear()} Copyright: TrimyLink
      </div>
    </footer>
  );
}
