import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

ChartJS.register(ArcElement, Tooltip, Legend);
export function TopDevicesAnaltyics({
  errorText,
  setErrorText,
  from,
  to,
}: {
  errorText: string;
  setErrorText: any;
  from: any;
  to: any;
}) {
  const [devices, setDevices] = useState<any>([]);
  const [type, setType] = useState('Devices');
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [showError, setShowError] = useState(false);

  async function fetchDevices() {
    setLoadingVisible(true);
    const requestId = Date.now().toString() + ' - Devices';
    let url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/devices?type=${type}`;
    if (from && to) {
      url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/devices?type=${type}&from=${from}&to=${to}`;
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
      });
      setLoadingVisible(false);

      if (response.ok) {
        const responseData = await response.json();
        const labels: string[] = [];
        const counts: number[] = [];
        responseData.map((device: any) => {
          labels.push(device.name);
          counts.push(device.percentage);
        });
        const data = {
          labels: labels,
          datasets: [
            {
              label: '%',
              data: counts,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };
        setDevices(data);
        setErrorText('');
        setShowError(false);
      } else {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else if (response.status !== 403) {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId}`
      );
      setShowError(true);
    }
  }
  useEffect(() => {
    fetchDevices();
  }, [type, from, to]);

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Row className='y-flex align-items-center justify-content-center center mt-5'>
          <h5 style={{ color: '#2A4978' }}>
            Statistics By Device, Browser, OS
          </h5>
          <div className='d-flex justify-content-center'>
            <Dropdown>
              <Dropdown.Toggle variant='outline-success' id='dropdown-basic'>
                Statistics By
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setType('DEVICES')}>
                  Devices
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setType('BROWSERS')}>
                  Browser
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setType('os')}>OS</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {devices &&
          devices.labels &&
          devices.datasets &&
          devices.labels.length > 0 ? (
            <Pie data={devices} className='mt-3' />
          ) : (
            <p className='text-danger mt-2'>
              No data available, try change your <b>date range</b> or{' '}
              <b>Statistics By </b>
              filter
            </p>
          )}
        </Row>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
