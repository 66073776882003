import { useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import './contactus.css';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Header from './home-page/Header';
import { Footer } from './home-page/Footer';
import { ErrorTextModal } from './popup/ErrorTextModal';
import { SuccessTextModal } from './popup/SuccessTextModal';
export function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [refernceNumber, setRefernceNumber] = useState('');
  const [errorText, setErrorText] = useState<string>('');
  const [successText, setSuccessText] = useState<string>('');
  const [showError, setShowError] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const requestId = Date.now().toString();
    const response = await fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/contactus`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
        },
        body: JSON.stringify({
          name,
          email,
          title: subject,
          message,
          referenceNumber: refernceNumber,
        }),
      }
    );
    if (!response.ok) {
      setSuccessText('');
      if (response.status === 400) {
        const error = (await response.json()).message;
        setErrorText(error);
        setShowError(true);
      } else {
        setErrorText('Something went wrong, please try again later');
        setShowError(true);
      }
    } else {
      setErrorText('');
      setShowError(false);
      setSuccessText('Your message has been sent successfully');
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setRefernceNumber('');
    }
    // const data = await response.json();
  };

  return (
    <Container className='mt-5'>
      <Header />
      <ToastContainer position='top-center' />
      <Row className='justify-content-center mt-5'>
        <Col md={10}>
          <div className='wrapper mt-5'>
            <Row noGutters className=''>
              <Col md={6}>
                <div className='contact-wrap w-100 p-lg-5 p-4'>
                  <h3 className='mb-4'>Send us a message</h3>
                  <Form
                    id='contactForm'
                    className='contactForm'
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Control
                            type='text'
                            name='name'
                            placeholder='Name'
                            className='contact-us-form-control'
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Control
                            type='email'
                            name='email'
                            placeholder='Email'
                            className='contact-us-form-control'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Control
                            type='text'
                            name='subject'
                            placeholder='Subject'
                            className='contact-us-form-control'
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group>
                          <Form.Control
                            type='text'
                            name='subject'
                            className='contact-us-form-control'
                            placeholder='Reference Number (Optional)'
                            onChange={(e) => setRefernceNumber(e.target.value)}
                            value={refernceNumber}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Control
                            as='textarea'
                            name='message'
                            className='contact-us-form-control'
                            placeholder='Message'
                            cols={30}
                            rows={6}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group>
                          <Button
                            type='submit'
                            className='contactus-btn contactus-btn-primary'
                          >
                            Send Message
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col md={6} className='d-flex align-items-stretch'>
                <div className='info-wrap w-100 p-lg-5 p-4 img'>
                  <h3>Contact us</h3>
                  <p className='mb-4'>
                    We're open for any suggestion or just to have a chat
                  </p>
                  <div className='dbox w-100 d-flex align-items-start'>
                    {/* <div className='icon d-flex align-items-center justify-content-center'> */}
                    <Row className='d-flex align-items-center justify-content-center center'>
                      <Col xs='auto'>
                        <FontAwesomeIcon icon={faLocationDot} />
                      </Col>
                      <Col>
                        <p>
                          <span>Address:</span> Dubai , United Arab Emirates
                        </p>
                      </Col>
                    </Row>
                    {/* </div> */}
                  </div>

                  <div className='dbox w-100 d-flex align-items-start'>
                    {/* <div className='icon d-flex align-items-center justify-content-center'> */}
                    <Row className='d-flex align-items-center justify-content-center center'>
                      <Col xs='auto'>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </Col>
                      <Col>
                        <p>
                          <span>support@trimylink.io</span>
                        </p>
                      </Col>
                    </Row>
                    {/* </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Footer />
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />

      <SuccessTextModal
        successText={successText}
        show={successText.length > 0}
        onHide={() => setSuccessText('')}
      />
    </Container>
  );
}
