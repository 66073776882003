import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Footer } from './home-page/Footer';
import Header from './home-page/Header';
import Hero from './home-page/Hero';
import { useContext, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { TrimyLinkContext } from '../store/TrimyLinkContext';
import { ErrorTextModal } from './popup/ErrorTextModal';

export function Pricing() {
  const [tiers, setTiers] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);

  const requestId = Date.now().toString();
  const currentTier = localStorage.getItem('tier') as string;
  const navigate = useNavigate();
  const prevTiersRef = useRef<string[]>();
  const trimzCtx = useContext(TrimyLinkContext);

  const fetchPackages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/subscription/packages`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          credentials: 'include',
        }
      );
      const result = await response.json();
      if (response.ok) {
        setTiers(result.filter((tier: any) => tier.name !== currentTier));
        setErrorText('');
        setShowError(false);
      } else {
        if (response.status === 400) {
          const error = result.message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
        return;
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId}`
      );
      setShowError(true);
    }
  };

  useEffect(() => {
    if (JSON.stringify(prevTiersRef.current) !== JSON.stringify(tiers)) {
      fetchPackages();
      prevTiersRef.current = tiers;
    } else {
      prevTiersRef.current = tiers;
    }
  }, [trimzCtx, currentTier]);

  return (
    <>
      <Header />
      <Hero />
      <Container
        className='y-flex align-items-center justify-content-center'
        style={{ minHeight: '100vh' }}
      >
        <Row>
          {tiers
            .sort((a: any, b: any) => a.order - b.order)

            .map((tier: any) => (
              <Col lg={3} className='mb-4 position-relative' key={tier.name}>
                {tier.order === 2 && (
                  <div
                    className='badge bg-primary text-white text-center py-3 mb-3'
                    style={{
                      position: 'absolute',
                      top: '-2.5rem',
                      left: '15px',
                      right: '15px',
                      zIndex: 1,
                      backgroundColor: '#007bff',
                    }}
                  >
                    Most Popular
                  </div>
                )}
                <Card className='text-center position-relative d-flex flex-column'>
                  <Card.Header className='pt-4'>
                    <b>{tier.name}</b>
                  </Card.Header>
                  <p className='mt-3'>
                    <b>{tier.price}</b>
                  </p>

                  <Card.Body className='flex-grow-1'>
                    <Card.Text>
                      <ul>
                        {tier.features.map((feature: any) => (
                          <li className='mt-2 small' key={feature}>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                    <hr className='mt-0' />
                    <Button
                      className='rounded choose-plan-button'
                      style={{
                        backgroundColor:
                          tier.order === 2 ? '#007bff' : '#edf2fe',
                        color: tier.order === 2 ? 'white' : '#2a5bd7',
                        border: 'none',
                        width: '100%',
                      }}
                      hidden={currentTier !== null && 'Free' !== currentTier}
                      onClick={() => {
                        localStorage.getItem('jwtToken')
                          ? navigate('/manage-subscription')
                          : navigate('/signup', { state: { plan: tier } });
                      }}
                    >
                      {localStorage.getItem('tier')
                        ? `Upgrade to ${tier.name}`
                        : 'Choose Plan'}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>

        <h3
          className='text-center'
          style={{
            color: '#007bff',
            backgroundColor: '#f8f9fa',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          Choose Your Perfect Plan
        </h3>
        <h3 className='mt-5 ' style={{ color: '#007bff' }}>
          Link Management
        </h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Feature</th>
              {tiers.map((tier: any) => (
                <th key={tier.name}>{tier.name}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Short URL</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.shortenUrlDetails.shortLinks}</td>
              ))}
            </tr>
            <tr>
              <td>Redirects</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.shortenUrlDetails.redirects}</td>
              ))}
            </tr>
            <tr>
              <td>Custom Back Halves</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.shortenUrlDetails.customBackHalves}
                </td>
              ))}
            </tr>
            <tr>
              <td>Link Clicks</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.shortenUrlDetails.linkClicks}</td>
              ))}
            </tr>
            <tr>
              <td>Bulk Shortening</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.shortenUrlDetails.bulkShortening}</td>
              ))}
            </tr>
          </tbody>
          <h3 className='mt-5 ' style={{ color: '#007bff' }}>
            QR Codes
          </h3>
          <tbody>
            <tr>
              <td>QR Codes</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.qrCodesDetails.qrCodes}</td>
              ))}
            </tr>
            <tr>
              <td>Redirects</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.qrCodesDetails.redirects}</td>
              ))}
            </tr>

            <tr>
              <td>Color Options</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.qrCodesDetails.colorOptions}</td>
              ))}
            </tr>
            <tr>
              <td>QR Code Scans</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.qrCodesDetails.qrCodeScans}</td>
              ))}
            </tr>
            <tr>
              <td>Download Format</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.qrCodesDetails.downlodFormat}</td>
              ))}
            </tr>
          </tbody>
          <h3 className='mt-5 ' style={{ color: '#007bff' }}>
            Link In Bio
          </h3>
          <tbody>
            <tr>
              <td>Quantity</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.linkInBio.quantity}</td>
              ))}
            </tr>
            <tr>
              <td>Pre Designed Themes</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.linkInBio.preDesignedThemes ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Custom Background Colors</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.linkInBio.customBackgroundColors ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Profile And Background Image Upload</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.linkInBio.profileAndBackgroundImageUpload
                    ? 'Yes'
                    : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Link In Bio Social Icons</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.linkInBio.linkInBioSocialIcons ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Link In Bio Qr Code</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.linkInBio.linkInBioQrCode ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
          </tbody>

          <h3 className='mt-5 ' style={{ color: '#007bff' }}>
            Analytics And Reporting
          </h3>

          <tbody>
            <tr>
              <td>Click Scan Data History</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.analyticsAndReporting.clickScanDataHistory}
                </td>
              ))}
            </tr>
            <tr>
              <td>Analytics Custom Date Range</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.analyticsAndReporting.analyticsCustomDateRange}
                </td>
              ))}
            </tr>
            <tr>
              <td>Country Level Data</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.analyticsAndReporting.countryLevelData}
                </td>
              ))}
            </tr>
            <tr>
              <td>Link In Bio Views</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.analyticsAndReporting.linkInBioViews ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Link In Bio Performance History</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.analyticsAndReporting.linkInBioPerformanceHistory}
                </td>
              ))}
            </tr>
            <tr>
              <td>Link In Bio Analytics Custom Date Range</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.analyticsAndReporting.linkInBioAnalyticsCustomDateRange}
                </td>
              ))}
            </tr>
          </tbody>

          <h3 className='mt-5' style={{ color: '#007bff' }}>
            API Access
          </h3>
          <tbody>
            <tr>
              <td>API Requests</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>{tier.apiAccess.apiRequests}</td>
              ))}
            </tr>
            <tr>
              <td>Integrations</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.apiAccess.Integrations ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
          </tbody>

          <h3 className='mt-5' style={{ color: '#007bff' }}>
            Custom Domain
          </h3>
          <tbody>
            <tr>
              <td>Complimentary Custom Domain</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.customDomain.complimentaryCustomDomain ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Domain Finder</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.customDomain.domainFinder ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>One Step Setup</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.customDomain.oneStepSetup ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Domain Not Found Fixer</td>
              {tiers.map((tier: any) => (
                <td key={tier.name}>
                  {tier.customDomain.domainNotFoundFixer ? 'Yes' : 'No'}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        <hr className='mt-5 mb-5'></hr>
      </Container>
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
      <Footer />
    </>
  );
}
